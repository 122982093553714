import React from "react";
import { connect } from "react-redux";
import { IProduct } from "../../redux/modules/products";
import { createErrorNotification } from "../../redux/modules/notifications";
import { addUseOperation, UseOperation } from "../../redux/modules/operations";
import { getCurrentDateInISO } from "../../utils";

interface UseOperationFormProps {
  product: IProduct;
  visitId?: number;
  addUseOperation: (operation: UseOperation, syncCallBack?: () => void) => void;
  createErrorNotification?: (message: string) => void;
  syncCallBack?: () => void;
}

interface UseOperationFormState {
  date: string;
  amount: number;
  visitId?: number;
}

class UseOperationForm extends React.Component<UseOperationFormProps, UseOperationFormState> {
  constructor(props: UseOperationFormProps) {
    super(props);
    this.state = {
      amount: 1,
      date: getCurrentDateInISO(),
      visitId: props.visitId
    };
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(event.target.value);
    this.setState({ amount });
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    this.setState({ date });
  };

  save = () => {
    if (this.state.date && this.state.amount) {
      this.props.addUseOperation &&
        this.props.addUseOperation(
          { productId: this.props.product.id, amount: this.state.amount, date: this.state.date, visitId: this.state.visitId },
          this.props.syncCallBack
        );
    } else {
      this.props.createErrorNotification && this.props.createErrorNotification("Потрібно вказати дату та кількість");
    }
  };

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <p>Списати товар</p>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Кількість товару, {this.props.product.unitType}</label>
          <input type="number" className="form-control" onChange={this.handleAmountChange} id="formGroupExampleInput" value={this.state.amount} required />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Дата операції</label>
          <input type="date" className="form-control" onChange={this.handleDateChange} required id="formGroupExampleInput2" value={this.state.date} />
        </div>
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Списати
        </button>
      </form>
    );
  }
}

export default connect(null, { createErrorNotification, addUseOperation })(UseOperationForm);
