import React from "react";
import { connect } from "react-redux";
import { IProduct } from "../../redux/modules/products";
import { createErrorNotification } from "../../redux/modules/notifications";
import { addSupplyOperation, SupplyOperation } from "../../redux/modules/operations";
import { getCurrentDateInISO } from "../../utils";

interface SupplyOperationFormProps {
  product: IProduct;
  addSupplyOperation: (operation: SupplyOperation) => void;
  createErrorNotification?: (message: string) => void;
  callback: () => void;
}

interface SupplyOperationFormState {
  date: string;
  amount: number;
}

class SupplyOperationForm extends React.Component<SupplyOperationFormProps, SupplyOperationFormState> {
  constructor(props: SupplyOperationFormProps) {
    super(props);
    this.state = {
      amount: 1,
      date: getCurrentDateInISO()
    };
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(event.target.value);
    this.setState({ amount });
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    this.setState({ date });
  };

  save = () => {
    if (this.state.date && this.state.amount) {
      this.props.addSupplyOperation && this.props.addSupplyOperation({ productId: this.props.product.id, amount: this.state.amount, date: this.state.date });
      this.props.callback();
    } else {
      this.props.createErrorNotification && this.props.createErrorNotification("Потрібно вказати дату та кількість");
    }
  };

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <p>Поповнити запаси товару</p>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Кількість товару, {this.props.product.unitType}</label>
          <input type="number" className="form-control" onChange={this.handleAmountChange} id="formGroupExampleInput" value={this.state.amount} required />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Дата операції</label>
          <input type="date" className="form-control" onChange={this.handleDateChange} required id="formGroupExampleInput2" value={this.state.date} />
        </div>
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Поповнити
        </button>
      </form>
    );
  }
}

export default connect(null, { createErrorNotification, addSupplyOperation })(SupplyOperationForm);
