import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IProduct, deleteProduct, getProducts } from "../../../redux/modules/products";
import { RootState } from "../../../redux";
import TypeaheadSearch, { SearchType } from "../../TypeaheadSearch";

interface ProductListProps {
  products?: IProduct[];
  count?: number;
  deleteProduct?: (productId: number) => void;
  getProducts?: (page: number) => Promise<void>;
}

type ProductListState = {
  hideArchived: boolean;
  page: number;
};

class ProductListPage extends React.Component<ProductListProps, ProductListState> {
  constructor(props: ProductListProps) {
    super(props);
    this.state = { hideArchived: true, page: 0 };
  }

  renderProducts() {
    if (!this.props.products) {
      return <p>Триває пошук...</p>;
    }
    return this.props.products
      .sort((c1, c2) => (c1.name < c2.name ? -1 : 1))
      .map((product) => {
        if (this.state.hideArchived && product.archived) {
          return null;
        }

        return (
          <li className="list-group-item" key={product.id}>
            <div className="row">
              <div className="col-8">
                <Link to={`/product/${product.id}`}>{product.name}</Link>
              </div>
              <div className="col-4 text-right">{this.renderButtons(product)}</div>
            </div>
          </li>
        );
      });
  }

  renderButtons(product: IProduct) {
    if (!product.archived) {
      return (
        <button type="button" className="btn btn-outline-danger" onClick={() => this.archiveProduct(product)}>
          X
        </button>
      );
    }
    return (
      <button type="button" className="btn btn-outline-danger">
        Не Активний
      </button>
    );
  }

  render() {
    return (
      <div>
        <h3>Товари:</h3>
        <Link to="/new-product">
          <button type="button" className="btn btn-outline-primary btn-lg btn-block">
            Додати Товар
          </button>
        </Link>
        <form>
          <TypeaheadSearch searchType={SearchType.PRODUCTS} />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={this.state.hideArchived}
              onChange={() => this.toggleArchived()}
              id="flexCheckChecked"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Сховати архівні товари
            </label>
          </div>
          <br />
        </form>
        <ul className="list-group">{this.renderProducts()}</ul>
        <br></br>
        {this.renderLoadMoreButton()}
        <br></br>
      </div>
    );
  }

  renderLoadMoreButton = () => {
    if (this.props.products && this.props.count && this.props.products.length && this.props.products.length < this.props.count) {
      return (
        <button className="btn btn-outline-primary" onClick={() => this.loadMore()}>
          Завантажити ще
        </button>
      );
    }
  };

  loadMore = () => {
    const page = this.state.page + 1;
    this.setState({ page });
    this.props.getProducts && this.props.getProducts(page);
  };

  toggleArchived = () => {
    this.setState({ hideArchived: !this.state.hideArchived });
  };

  archiveProduct = (product: IProduct) => {
    this.props.deleteProduct && this.props.deleteProduct(product.id);
  };

  componentDidMount() {
    this.props.getProducts && this.props.getProducts(0);
  }
}

const mapStateToProps = (state: RootState) => {
  return { products: Object.values(state.productsState.products), count: state.productsState.count };
};

export default connect(mapStateToProps, { getProducts, deleteProduct })(ProductListPage);
