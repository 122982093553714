import React from "react";
import { Link } from "react-router-dom";
import TypeaheadSearch, { SearchType } from "../../TypeaheadSearch";

const Dashboard = () => {
  return (
    <div className="dashboard container">
      <h1 className="dashboard__title">Домівка</h1>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <ul className="list-group">
              <li className="list-group-item">
                <Link to="/client-list">Клієнти</Link>
              </li>
              <li className="list-group-item">
                <Link to="/new-visit">Створити Візит</Link>
              </li>
              <li className="list-group-item">
                <Link to="/product-list">Склад</Link>
              </li>
              <li className="list-group-item">
                <Link to="/service-list">Каталог Послуг</Link>
              </li>
              <li className="list-group-item">
                <Link to="/reports">Звіти</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-5">
            <div>
              <br></br>
              <TypeaheadSearch searchType={SearchType.CLIENTS} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
