import axios from "axios";
import { createErrorNotification } from "../notifications";
import { ThunkDispatchType } from "..";
import { IClient } from "../clients";
import { IProduct } from "../products";
import { IService } from "../services";

export enum SearchActionType {
  SEARCH_CLIENTS = "SEARCH_CLIENTS",
  SEARCH_SERVICES = "SEARCH_SERVICES",
  SEARCH_PRODUCTS = "SEARCH_PRODUCTS",
  CLEAR_SEARCH = "CLEAR_SEARCH"
}

export type ISearchClient = Omit<IClient, "birth" | "phone" | "email" | "notes" | "archived" | "visits" | "operations">;
export type ISearchProduct = Omit<IProduct, "notes" | "basePrice" | "sellPrice" | "archived">;
export type ISearchService = Omit<IService, "notes" | "archived">;

export type SearchClientsAction = {
  type: typeof SearchActionType.SEARCH_CLIENTS;
  payload: ISearchClient[];
};

export type SearchProductsAction = {
  type: typeof SearchActionType.SEARCH_PRODUCTS;
  payload: ISearchProduct[];
};

export type SearchServicesAction = {
  type: typeof SearchActionType.SEARCH_SERVICES;
  payload: ISearchService[];
};

export type ClearSearchAction = {
  type: typeof SearchActionType.CLEAR_SEARCH;
};

export type SearchAction = SearchClientsAction | ClearSearchAction | SearchProductsAction | SearchServicesAction;

type SearchState = { clients: ISearchClient[]; products: ISearchProduct[]; services: ISearchService[] };

const initialState: SearchState = {
  clients: [],
  products: [],
  services: []
};

const reducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case SearchActionType.CLEAR_SEARCH:
      return { clients: [], products: [], services: [] };
    case SearchActionType.SEARCH_CLIENTS:
      return { ...state, clients: action.payload };
    case SearchActionType.SEARCH_PRODUCTS:
      return { ...state, products: action.payload };
    case SearchActionType.SEARCH_SERVICES:
      return { ...state, services: action.payload };
    default:
      return state;
  }
};

export default reducer;

export const searchClients = (searchQuery: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axios.get(`/api/v2/search/clients?query=${searchQuery}`);
    dispatch({ type: SearchActionType.SEARCH_CLIENTS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const searchProducts = (searchQuery: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axios.get(`/api/v2/search/products?query=${searchQuery}`);
    dispatch({ type: SearchActionType.SEARCH_PRODUCTS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const searchServices = (searchQuery: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axios.get(`/api/v2/search/services?query=${searchQuery}`);
    dispatch({ type: SearchActionType.SEARCH_SERVICES, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const clearSearch = () => (dispatch: ThunkDispatchType) => {
  try {
    dispatch({ type: SearchActionType.CLEAR_SEARCH });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};
