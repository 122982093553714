import React from "react";
import ReportList from "../../ReportList";

const ReportsPage = () => {
  return (
    <div className="reports container">
      <p>Reports</p>
      <ReportList />
    </div>
  );
};

export default ReportsPage;
