import React from "react";
import { connect } from "react-redux";
import { getProduct, IProduct, updateProduct } from "../../../redux/modules/products";
import ProductForm from "../../ProductForm";
import { withRouter } from "../../../hooks/withRouter";
import { RootState } from "../../../redux";
import ProductOperationList from "../../ProductOperationList";
import SupplyOperationForm from "../../SupplyOperationForm";
import SellOperationForm from "../../SellOperationForm";
import UseOperationForm from "../../UseOperationForm";

interface ProductPageProps {
  products?: IProduct[];
  router?: { params?: { id?: string }; location?: { search?: string } };
  getProduct?: (productId: number) => void;
  updateProduct?: (product: IProduct) => void;
}

interface ProductPageState {
  showSupplyForm: boolean;
  showSellForm: boolean;
  showUseForm: boolean;
}

class ProductPage extends React.Component<ProductPageProps, ProductPageState> {
  constructor(props: ProductPageProps) {
    super(props);
    this.state = {
      showSellForm: false,
      showUseForm: false,
      showSupplyForm: false
    };
  }

  componentDidMount() {
    const id = this.props?.router?.params?.id;
    if (!id) {
      return;
    }
    this.props.getProduct && this.props.getProduct(+id);
  }

  showForm = (form: string) => {
    if (form === "SUPPLY") {
      this.setState({
        showSellForm: false,
        showUseForm: false,
        showSupplyForm: true
      });
    } else if (form === "SELL") {
      this.setState({
        showSellForm: true,
        showUseForm: false,
        showSupplyForm: false
      });
    } else if (form === "USE") {
      this.setState({
        showSellForm: false,
        showUseForm: true,
        showSupplyForm: false
      });
    }
  };

  hideForms = () => {
    this.setState({
      showSellForm: false,
      showUseForm: false,
      showSupplyForm: false
    });
  };

  renderProductForm(product: IProduct) {
    if (this.state.showSellForm || this.state.showSupplyForm || this.state.showUseForm) {
      return (
        <button className="btn btn-danger" onClick={this.hideForms}>
          Cкасувати
        </button>
      );
    } else {
      return (
        <>
          <ProductForm product={product} saveHandler={this.props.updateProduct} />
          <div className="operations-btn-container">
            <button className="btn btn-outline-primary" onClick={() => this.showForm("SUPPLY")}>
              Поповнити товар
            </button>
            {this.renderSellAndUseButtons(product)}
          </div>
          <ProductOperationList product={product} />
        </>
      );
    }
  }

  renderSellAndUseButtons(product: IProduct) {
    if (!product.amount) {
      return null;
    }
    return (
      <>
        <button className="btn btn-outline-primary" onClick={() => this.showForm("SELL")}>
          Продати товар
        </button>
        <button className="btn btn-outline-primary" onClick={() => this.showForm("USE")}>
          Списати товар
        </button>
      </>
    );
  }

  renderOperationForms = (product: IProduct) => {
    if (this.state.showSupplyForm) {
      return <SupplyOperationForm product={product} callback={this.hideForms} />;
    } else if (this.state.showSellForm) {
      return <SellOperationForm product={product} callback={this.hideForms} />;
    } else if (this.state.showUseForm) {
      return <UseOperationForm product={product} syncCallBack={this.hideForms} />;
    }
  };

  renderProductData() {
    const pageId = this.props?.router?.params?.id;
    if (!this.props.products || !pageId) {
      return;
    }
    const product = this.props.products.find(({ id }) => id === +pageId);
    if (!product) {
      return <p>Product with id: {pageId}</p>;
    }
    return (
      <div>
        {this.renderProductForm(product)}
        {this.renderOperationForms(product)}
      </div>
    );
  }

  render() {
    if (this.props.products) {
      return this.renderProductData();
    }
    return <p>Loading product with id: {this.props?.router?.params?.id}</p>;
  }
}

const mapStateToProps = (state: RootState) => {
  return { products: Object.values(state.productsState.products) };
};

export default withRouter(
  connect(mapStateToProps, {
    getProduct,
    updateProduct
  })(ProductPage)
);
