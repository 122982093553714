import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { Notification } from "../../redux/modules/notifications";
import "./index.css";

type NotificationProps = {
  notifications?: Notification[];
};

class NotificationList extends React.Component<NotificationProps> {
  renderNotifications() {
    if (!this.props.notifications) {
      return null;
    }
    return this.props.notifications.map((n) => {
      return (
        <div className={`alert alert-${n.type}`} role="alert" key={n.id}>
          {n.message}
        </div>
      );
    });
  }

  render() {
    return <div className="notifications-container">{this.renderNotifications()}</div>;
  }
}

const mapStateToProps = (state: RootState) => {
  return { notifications: state.notifications };
};

export default connect(mapStateToProps, {})(NotificationList);
