import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";

type SpinnerProps = {
  showSpinner: boolean;
};

class Spinner extends React.Component<SpinnerProps> {
  render() {
    if (!this.props.showSpinner) {
      return null;
    }
    return (
      <div className="overlay">
        <img className="spinner" src="/loading.gif" alt="Loading..."></img>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { showSpinner: state.spinnerReducer };
};

export default connect(mapStateToProps, null)(Spinner);
