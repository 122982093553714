import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { IOperation } from "../../redux/modules/operations";
import { IReport, getReports } from "../../redux/modules/reports";
import { Link } from "react-router-dom";
import { formatPrice, formatDate } from "../../utils";

type ReportListProps = {
  getReports?: (s1: string, s2: string) => void;
  reports?: IReport;
};

type ReportListState = {
  startDate: string;
  endDate: string;
};

class ReportList extends React.Component<ReportListProps, ReportListState> {
  constructor(props: ReportListProps) {
    super(props);

    const startDate = new Date();
    const endDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    this.state = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };
  }

  handleStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startDate: event.target.value });
  };

  handleEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endDate: event.target.value });
  };

  save = () => {
    this.props.getReports && this.props.getReports(this.state.startDate, this.state.endDate);
  };

  renderVisits = () => {
    if (!this.props.reports || !this.props.reports.visits) {
      return null;
    }
    const table = this.props.reports.visits
      .sort((v1, v2) => {
        return new Date(v2.date).getTime() - new Date(v1.date).getTime();
      })
      .map((visit) => {
        const price = visit.services.map((serviceDto) => serviceDto.service.price).reduce((s1, s2) => s1 + s2, 0);
        return (
          <li className="list-group-item" key={visit.id}>
            <div className="row">
              <div className="col-3">{formatDate(visit?.date)}</div>
              <div className="col-3 text-left">
                <Link to={`/client/${visit.clientId}`}>{visit.client.name}</Link>
              </div>
              <div className="col-3 text-right">
                <Link to={`/visit/${visit.id}`}>{visit.title}</Link>
              </div>
              <div className="col-3 text-right">{formatPrice(price)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-3">Дата</div>
          <div className="col-3 text-left">Клієнт</div>
          <div className="col-3 text-right">Візит</div>
          <div className="col-3 text-right">Вартість, грн</div>
        </div>
      </li>
    );

    return table;
  };

  renderSupplyOperations = () => {
    if (!this.props.reports || !this.props.reports.supplyOperations) {
      return;
    }
    const table = this.props.reports.supplyOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        if (!operation?.product?.basePrice) {
          return null;
        }
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-3">{formatDate(operation.date)}</div>
              <div className="col-4 text-left">
                <Link to={`/product/${operation.productId}`}>{operation.product.name}</Link>
              </div>
              <div className="col-2 text-right">
                <span>{operation.amount}</span>
              </div>
              <div className="col-3 text-right">{formatPrice(operation.amount * operation.product.basePrice)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-3">Дата</div>
          <div className="col-4 text-left">Назва Товару</div>
          <div className="col-2 text-right">
            <span>Додано одиниць</span>
          </div>
          <div className="col-3 text-right">Вартість, грн</div>
        </div>
      </li>
    );

    return table;
  };

  renderSellOperations = () => {
    if (!this.props.reports || !this.props.reports.sellOperations) {
      return;
    }
    const table = this.props.reports.sellOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        if (!operation?.product?.basePrice || !operation.sellPrice) {
          return null;
        }
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-3">{formatDate(operation.date)}</div>
              <div className="col-4 text-left">
                <Link to={`/product/${operation.productId}`}>{operation?.product?.name}</Link>
              </div>
              <div className="col-2 text-right">
                <span>{operation.amount}</span>
              </div>
              <div className="col-3 text-right">{formatPrice((operation.sellPrice - operation?.product?.basePrice) * operation.amount)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-3">Дата</div>
          <div className="col-4 text-left">Назва Товару</div>
          <div className="col-2 text-right">
            <span>Продано одиниць</span>
          </div>
          <div className="col-3 text-right">Вартість - Собівартість, грн</div>
        </div>
      </li>
    );

    return table;
  };

  renderUseOperations = () => {
    if (!this.props.reports || !this.props.reports.useOperations) {
      return;
    }
    const table = this.props.reports.useOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        if (!operation?.product?.basePrice) {
          return null;
        }
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-3">{formatDate(operation.date)}</div>
              <div className="col-4 text-left">
                <Link to={`/product/${operation.productId}`}>{operation.product.name}</Link>
              </div>
              <div className="col-2 text-right">
                <span>{operation.amount}</span>
              </div>
              <div className="col-3 text-right">{formatPrice(operation?.product?.basePrice * operation.amount)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-3">Дата</div>
          <div className="col-4 text-left">Назва Товару</div>
          <div className="col-2 text-right">
            <span>Списано одиниць</span>
          </div>
          <div className="col-3 text-right">Собівартість, грн</div>
        </div>
      </li>
    );

    return table;
  };

  renderData = () => {
    if (!this.props.reports || this.props.reports.spentOnSupplies === undefined) {
      return null;
    }
    return (
      <>
        <ul className="list-group">
          <br></br>
          <h4>Операції поповнення</h4>
          {this.renderSupplyOperations()}
          <br></br>
          <p>Загальна сума витрат: {formatPrice(this.props.reports?.spentOnSupplies)}грн</p>
        </ul>
        <ul className="list-group">
          <br></br>
          <h4>Операції Продажу</h4>
          {this.renderSellOperations()}
          <br></br>
          <p>Загальна сума заробітку від продажу: {formatPrice(this.props.reports?.earnedOnProducts || 0)} грн</p>
          <p>Загальна сума повернена в касу від продажу: {formatPrice(this.props.reports?.retunedMoneyOnProducts || 0)} грн</p>
        </ul>
        <ul className="list-group">
          <br></br>
          <h4>Операції Списання</h4>
          {this.renderUseOperations()}
          <br></br>
          <p>Загальна вартість списаного товару: {formatPrice(this.props.reports?.spentOnUseOperations || 0)} грн</p>
          <br></br>
        </ul>
        <ul className="list-group">
          <br></br>
          <h4>Візити</h4>
          {this.renderVisits()}
          <br></br>
          <p>Загальна сума заробітку від візитів: {formatPrice(this.props.reports?.earnedOnVisits || 0)}грн</p>
          <br></br>
          <br></br>
        </ul>
      </>
    );
  };

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="report-container">
          <div className="form-group">
            <label htmlFor="formGroupExampleInput">Від</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => this.handleStartDate(event)}
              id="formGroupExampleInput"
              value={this.state.startDate ? formatDate(this.state.startDate) : ""}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="formGroupExampleInput">До</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => this.handleEndDate(event)}
              id="formGroupExampleInput3"
              value={this.state.endDate ? formatDate(this.state.endDate) : ""}
              required
            />
          </div>
          <br></br>
          <button className="btn btn-primary" onClick={this.save}>
            Пошук
          </button>
          {this.renderData()}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { reports: state.reports };
};

export default connect(mapStateToProps, { getReports })(ReportList);
