import React from "react";
import { connect } from "react-redux";
import ProductForm from "../../ProductForm";
import { IProduct, addProduct } from "../../../redux/modules/products";
import history from "../../../history";

interface CreateProductPageProps {
  addProduct?: (product: IProduct) => void;
}

class CreateProductPage extends React.Component<CreateProductPageProps> {
  saveProductHandler = (product: IProduct) => {
    this.props.addProduct && this.props.addProduct(product);
    history.push("/product-list");
  };

  render() {
    return (
      <div>
        <h1>Додати новий товар</h1>
        <ProductForm saveHandler={this.saveProductHandler} />
      </div>
    );
  }
}

export default connect(null, { addProduct })(CreateProductPage);
