import React from "react";
import { connect } from "react-redux";
import { addVisit, IVisit } from "../../../redux/modules/visits";
import history from "../../../history";
import { searchClients, clearSearch } from "../../../redux/modules/search";
import { RootState } from "../../../redux";
import VisitForm from "../../VisitForm";
import { withRouter } from "../../../hooks/withRouter";
import { ISearchClient } from "../../../redux/modules/search";

interface CreateVisitPageProps {
  addVisit?: (visit: Partial<IVisit>, callBack: (newVisit: IVisit) => void) => void;
  searchClients?: (query: string) => Promise<void>;
  clearSearch?: () => void;
  router?: { location?: { search?: string } };
  clients?: ISearchClient[];
}

interface CreateVisitState {
  clientId: number;
  clientName: string;
  filter: string;
}

class CreateVisitPage extends React.Component<CreateVisitPageProps, CreateVisitState> {
  constructor(props: CreateVisitPageProps) {
    super(props);
    const searchParams = props?.router?.location?.search;
    if (searchParams && searchParams.includes("?clientId=")) {
      const [clientId, clientName] = searchParams.slice(1, searchParams.length).split("&");
      this.state = { clientId: +clientId.split("=")[1], clientName: decodeURIComponent(clientName.split("=")[1]), filter: "" };
    } else {
      this.state = { clientId: 0, clientName: "", filter: "" };
    }
  }

  handleClientSelect = (clientId: number, clientName: string) => {
    this.setState({ clientId, clientName, filter: "" });
  };

  applyFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filter = e.target.value;
    if (filter && filter.length > 2) {
      this.props.searchClients && this.props.searchClients(filter);
    } else {
      this.props.clearSearch && this.props.clearSearch();
    }
    this.setState({ filter });
  };

  componentWillUnmount() {
    this.props.clearSearch && this.props.clearSearch();
  }

  renderClientList() {
    if (!this.props.clients) {
      return null;
    }
    return this.props.clients.map((c) => {
      return (
        <li className="list-group-item" key={c.id} onClick={() => this.handleClientSelect(c.id, c.name)}>
          {c.name}
        </li>
      );
    });
  }

  renderFirstStep() {
    if (this.state.clientId) {
      return (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            this.setState({ clientId: 0, clientName: "" });
          }}
        >
          Вибрати іншого клієнта
        </button>
      );
    }
    return (
      <div>
        <h3>Оберіть клієнта щоб продовжити</h3>
        <div className="form-group">
          <label className="form-check-label">Почніть вводити ім'я клієнта</label>
          <input className="form-control" type="text" value={this.state.filter} onChange={this.applyFilter} />
        </div>
        <ul className="list-group">{this.renderClientList()}</ul>
      </div>
    );
  }

  saveHandler = (visit: Partial<IVisit>) => {
    this.props.addVisit && this.props.addVisit(visit, (savedVisit: IVisit) => history.push(`/visit/${savedVisit.id}`));
  };

  renderSecondStep() {
    if (!this.state.clientId || !this.state.clientName) {
      return;
    }
    return <VisitForm clientId={this.state.clientId} clientName={this.state.clientName} saveHandler={this.saveHandler} />;
  }

  render() {
    return (
      <div>
        <h2>Створити візит</h2>
        {this.renderFirstStep()}
        {this.renderSecondStep()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { clients: state.searchState.clients };
};

export default withRouter(connect(mapStateToProps, { addVisit, clearSearch, searchClients })(CreateVisitPage));
