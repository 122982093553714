import React from "react";
import { connect } from "react-redux";
import { addClient, IClient } from "../../../redux/modules/clients";
import ClientForm from "../../ClientForm";
import history from "../../../history";

interface CreateClientProps {
  addClient?: (client: IClient, callBack: (savedClient: IClient) => void) => Promise<void>;
}

class CreateClientPage extends React.Component<CreateClientProps> {
  saveClientHandler = (client: IClient) => {
    this.props.addClient && this.props.addClient(client, (savedClient: IClient) => history.push(`/client/${savedClient.id}`));
  };

  render() {
    return (
      <div>
        <h1>Додаємо Клієнта</h1>
        <ClientForm saveHandler={this.saveClientHandler} />
      </div>
    );
  }
}

export default connect(null, { addClient })(CreateClientPage);
