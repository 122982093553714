import React from "react";
import { connect } from "react-redux";
import { IProduct } from "../../redux/modules/products";
import { createErrorNotification } from "../../redux/modules/notifications";
import { addSellOperation, SellOperation } from "../../redux/modules/operations";
import { ISearchClient, searchClients, clearSearch } from "../../redux/modules/search";
import { RootState } from "../../redux";
import { formatPrice, getCurrentDateInISO } from "../../utils";

interface SellOperationFormProps {
  product: IProduct;
  clients?: ISearchClient[];
  addSellOperation: (operation: SellOperation) => void;
  createErrorNotification?: (message: string) => void;
  callback: () => void;
  searchClients: (query: string) => Promise<void>;
  clearSearch: () => void;
}

interface SellOperationFormState {
  date: string;
  amount: number;
  sellPrice: number;
  discount: string;
  maxDiscount: number;
  clientId: number;
  clientName: string;
  filter: string;
}

class SellOperationForm extends React.Component<SellOperationFormProps, SellOperationFormState> {
  constructor(props: SellOperationFormProps) {
    super(props);
    this.state = {
      amount: 1,
      date: getCurrentDateInISO(),
      clientName: "",
      clientId: 0,
      filter: "",
      sellPrice: props.product.sellPrice,
      discount: "0",
      maxDiscount: ((props.product.sellPrice - props.product.basePrice) / props.product.sellPrice) * 100
    };
  }

  handleClientChange = (clientId: number, clientName: string) => {
    this.setState({ clientId, clientName, filter: "" });
    this.props.clearSearch && this.props.clearSearch();
  };

  applyFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filter = e.target.value;
    if (filter && filter.length > 2) {
      this.props.searchClients && this.props.searchClients(filter);
    } else {
      this.props.clearSearch && this.props.clearSearch();
    }
    this.setState({ filter });
  };

  componentWillUnmount() {
    this.props.clearSearch && this.props.clearSearch();
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(event.target.value);
    this.setState({ amount });
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    this.setState({ date });
  };

  handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(",", ".");
    const discount = parseFloat(event.target.value.replace(",", "."));
    if (isNaN(discount)) {
      this.props.createErrorNotification && this.props.createErrorNotification(`Невірний формат знижки ${rawValue}`);
      this.setState({ discount: rawValue });
    } else {
      const sellPrice = this.props.product.sellPrice * (1 - discount / 100);
      this.setState({ discount: rawValue, sellPrice });
    }
  };

  save = () => {
    const discount = parseFloat(this.state.discount);
    if (isNaN(discount)) {
      this.props.createErrorNotification && this.props.createErrorNotification(`Невірний формат знижки ${this.state.discount}`);
      return;
    }

    if (this.state.date && this.state.amount && this.state.sellPrice && this.state.clientId) {
      this.props.addSellOperation &&
        this.props.addSellOperation({
          clientId: this.state.clientId,
          productId: this.props.product.id,
          amount: this.state.amount,
          date: this.state.date,
          sellPrice: this.state.sellPrice
        });
      this.props.callback();
    } else {
      this.props.createErrorNotification && this.props.createErrorNotification("Потрібно вказати дату, кількість та клієнта");
    }
  };

  renderClientOptions = () => {
    if (!this.props.clients || !this.props.clients.length) {
      return;
    }
    return this.props.clients
      .sort((c1, c2) => (c1.name < c2.name ? -1 : 1))
      .map((client) => {
        return (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        );
      });
  };

  renderClientList() {
    if (!this.props.clients) {
      return null;
    }
    return this.props.clients.map((c) => {
      return (
        <li className="list-group-item" key={c.id} onClick={() => this.handleClientChange(c.id, c.name)}>
          {c.name}
        </li>
      );
    });
  }

  renderClientName() {
    if (this.state.clientName) {
      return (
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Клієнт</label>
          <input type="text" className="form-control" disabled id="formGroupExampleInput2" value={this.state.clientName} />
        </div>
      );
    }
  }

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <p>Продаж товару</p>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Кількість товару, {this.props.product.unitType}</label>
          <input type="number" className="form-control" onChange={this.handleAmountChange} id="formGroupExampleInput" value={this.state.amount} required />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">
            Вартість, (Собівартість: {this.props.product.basePrice}, Ціна за одиницю: {formatPrice(this.state.sellPrice)})
          </label>
          <input
            type="number"
            className="form-control-plaintext"
            id="formGroupExampleInput"
            value={formatPrice(this.state.sellPrice * this.state.amount)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Знижка, % (Максимальна знижка: {formatPrice(this.state.maxDiscount)}%)</label>
          <input type="text" className="form-control" onChange={this.handleDiscountChange} id="formGroupExampleInput" value={this.state.discount} />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Дата операції</label>
          <input type="date" className="form-control" onChange={this.handleDateChange} required id="formGroupExampleInput2" value={this.state.date} />
        </div>
        {this.renderClientName()}
        <div className="form-group">
          <div className="form-group">
            <label className="form-check-label">Пошук по імені</label>
            <input className="form-control" type="text" onChange={this.applyFilter} value={this.state.filter} />
          </div>
          <ul className="list-group">{this.renderClientList()}</ul>
        </div>
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Продати
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { clients: state.searchState.clients };
};

export default connect(mapStateToProps, { createErrorNotification, addSellOperation, searchClients, clearSearch })(SellOperationForm);
