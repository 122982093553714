import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getClient, IClient, updateClient } from "../../../redux/modules/clients";
import { deleteVisit } from "../../../redux/modules/visits";
import { IVisit } from "../../../redux/modules/visits";
import ClientForm from "../../ClientForm";
import { withRouter } from "../../../hooks/withRouter";
import history from "../../../history";
import { IOperation } from "../../../redux/modules/operations";
import { RootState } from "../../../redux";
import { formatDate } from "../../../utils";

interface ClientPageProps {
  clients?: IClient[];
  visits?: IVisit[];
  operations?: IOperation[];
  deleteVisit?: (visitId: number, client: IClient) => void;
  router?: { params?: { id?: string }; location?: { search?: string } };
  getClient?: (clientId: number) => void;
  updateClient?: (client: IClient) => void;
}

type ClientPageState = {
  activeTab: string;
};

class ClientPage extends React.Component<ClientPageProps, ClientPageState> {
  constructor(props: ClientPageProps) {
    super(props);
    const search = (props.router?.location?.search && props.router?.location?.search) || "";
    if (search.indexOf("tab=visits") > -1) {
      this.state = { activeTab: "visits" };
    } else if (search.indexOf("tab=products") > -1) {
      this.state = { activeTab: "products" };
    } else {
      this.state = { activeTab: "profile" };
    }
  }

  getId() {
    const id = this.props?.router?.params?.id;
    if (!id) {
      return 0;
    }
    return +id;
  }

  componentDidMount() {
    const id = this.getId();
    this.props.getClient && this.props.getClient(+id);
  }

  changeActiveTab = (tab: string) => {
    if (tab === this.state.activeTab) {
      return;
    }
    history.push({ search: `?tab=${tab}` });
    this.setState({ activeTab: tab });
  };

  renderClientData() {
    const pageId = this.props?.router?.params?.id;
    if (!this.props.clients || !pageId) {
      return;
    }
    const client = this.props.clients.find(({ id }) => id === +pageId);
    if (!client) {
      return <p>Client with id: {pageId}</p>;
    }
    return <ClientForm client={client} saveHandler={this.props.updateClient} />;
  }

  getClient() {
    const routerId = this.getId();

    if (!this.props.clients) {
      return null;
    }

    const client = this.props.clients.find(({ id }) => id === +routerId);
    if (!client) {
      return null;
    }
    return client;
  }

  handleAddVisit = () => {
    const routerId = this.props?.router?.params?.id;
    if (!this.props.clients || !routerId) {
      return;
    }
    const client = this.props.clients.find(({ id }) => id === +routerId);
    if (!client) {
      return;
    }
    history.push(`/new-visit?clientId=${client.id}&clientName=${encodeURIComponent(client.name)}`);
  };

  render() {
    return (
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a className={`nav-link ${this.state.activeTab === "profile" ? "active" : ""}`} onClick={() => this.changeActiveTab("profile")}>
              Профіль
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a className={`nav-link ${this.state.activeTab === "visits" ? "active" : ""}`} onClick={() => this.changeActiveTab("visits")}>
              Візити
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a className={`nav-link ${this.state.activeTab === "products" ? "active" : ""}`} onClick={() => this.changeActiveTab("products")}>
              Косметика
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <br />
          <div className={`tab-pane fade ${this.state.activeTab === "profile" ? "show active" : ""}`}>{this.renderClients()}</div>
          <div className={`tab-pane fade ${this.state.activeTab === "products" ? "show active" : ""}`}>
            <p>Продаж косметики:</p>
            <ul className="list-group">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-4">Назва</div>
                  <div className="col-4 text-center">Дата операції</div>
                  <div className="col-4 text-right">Кількість</div>
                </div>
              </li>
              {this.renderProducts()}
            </ul>
          </div>
          <div className={`tab-pane fade ${this.state.activeTab === "visits" ? "show active" : ""}`}>
            <button type="button" className="btn btn-secondary" onClick={this.handleAddVisit}>
              Створити Візит
            </button>
            <p>Візити:</p>
            <ul className="list-group">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-4">Опис</div>
                  <div className="col-4 text-center">Дата візиту</div>
                  <div className="col-4 "></div>
                </div>
              </li>
              {this.renderVisits()}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderProducts() {
    const client = this.getClient();
    if (!client || !client.operations || !client.operations.length) {
      return <p>Немає операцій</p>;
    }
    const pageId = this.props?.router?.params?.id;
    return client.operations
      .filter((operation) => {
        return pageId && operation.clientId === parseInt(pageId);
      })
      .sort((v1, v2) => {
        return new Date(v2.date).getTime() - new Date(v1.date).getTime();
      })
      .map((operation) => {
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-4">
                <Link to={`/product/${operation.productId}`}>{operation?.product?.name || operation.productId}</Link>
              </div>
              <div className="col-4 text-center">{formatDate(operation?.date)}</div>
              <div className="col-4 text-right">{operation.amount}</div>
            </div>
          </li>
        );
      });
  }

  deleteVisit = (visitId: number, client: IClient) => {
    this.props.deleteVisit && this.props.deleteVisit(visitId, client);
  };

  renderVisits() {
    const client = this.getClient();
    if (!client || !client.visits || !client.visits.length) {
      return <p>Немає жодного візиту</p>;
    }
    const pageId = this.props?.router?.params?.id;
    if (!pageId) {
      return <p>No page id</p>;
    }
    return client.visits
      .filter((visit) => {
        return visit.clientId === +pageId;
      })
      .sort((v1, v2) => {
        return new Date(v2.date).getTime() - new Date(v1.date).getTime();
      })
      .map((visit) => {
        return (
          <li className="list-group-item" key={visit.id}>
            <div className="row">
              <div className="col-4">
                <Link key={visit.id} to={`/visit/${visit.id}`}>
                  {visit.title}
                </Link>
              </div>
              <div className="col-4 text-center">{formatDate(visit?.date)}</div>
              <div className="col-4 text-right">
                <button type="button" className="btn btn-outline-danger" onClick={() => this.deleteVisit(visit.id, client)}>
                  X
                </button>
              </div>
            </div>
          </li>
        );
      });
  }

  renderClients() {
    if (this.props.clients && Object.keys(this.props.clients).length) {
      return this.renderClientData();
    }
    return <p>Loading client with id: {this.props?.router?.params?.id}</p>;
  }
}

const mapStateToProps = (state: RootState) => {
  return { count: state.clientsState.count, clients: Object.values(state.clientsState.clients), visits: state.visits, operations: state.operations };
};

export default withRouter(
  connect(mapStateToProps, {
    getClient,
    deleteVisit,
    updateClient
  })(ClientPage)
);
