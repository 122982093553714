import { Dispatch } from "redux";
import { GlobalActionType } from "..";

export const ERROR = "danger";
export const WARNING = "warning";
export const SUCCESS = "success";

export type NotificationType = typeof ERROR | typeof WARNING | typeof SUCCESS;

export enum NotificationActionType {
  ADD_NOTIFICATION = "ADD_NOTIFICATION",
  DELETE_NOTIFICATION = "DELET_NOTIFICATION"
}

export interface Notification {
  type: NotificationType;
  message: string;
  id: string;
}

export type AddNotificationAction = {
  type: typeof NotificationActionType.ADD_NOTIFICATION;
  payload: Notification;
};

export type DeleteNotificationAction = {
  type: typeof NotificationActionType.DELETE_NOTIFICATION;
  payload: string;
};

export type NotificationAction = AddNotificationAction | DeleteNotificationAction;

const initialState: Notification[] = [];

const reducer = (state = initialState, action: NotificationAction): Notification[] => {
  switch (action.type) {
    case NotificationActionType.ADD_NOTIFICATION:
      return [...state, action.payload];
    case NotificationActionType.DELETE_NOTIFICATION:
      return [...state.filter((n) => n.id !== action.payload)];
    default:
      return state;
  }
};

export default reducer;

export const createSuccessNotification = (message: string) => async (dispatch: Dispatch<GlobalActionType>) => {
  const id = Date.now() + "" + Math.random();
  setTimeout(() => dispatch(removeNotification(id)), 3500);

  return dispatch({
    type: NotificationActionType.ADD_NOTIFICATION,
    payload: {
      message,
      type: SUCCESS,
      id
    }
  });
};

export const createErrorNotification = (message: string) => async (dispatch: Dispatch<GlobalActionType>) => {
  const id = Date.now() + "" + Math.random();
  setTimeout(() => dispatch(removeNotification(id)), 3500);

  return dispatch({
    type: NotificationActionType.ADD_NOTIFICATION,
    payload: {
      message,
      type: ERROR,
      id
    }
  });
};

const removeNotification = (id: string): DeleteNotificationAction => {
  return {
    type: NotificationActionType.DELETE_NOTIFICATION,
    payload: id
  };
};
