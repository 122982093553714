import axios from "axios";
import axiosService from "../../../axios-service";
import { createErrorNotification } from "../notifications";
import { ThunkDispatchType } from "..";
import { IOperation } from "../operations";
import { IVisit } from "../visits";

export enum ReportsActionType {
  GET_REPORTS = "GET_REPORTS"
}

export interface IReport {
  spentOnSupplies?: number;
  earnedOnProducts?: number;
  earnedOnVisits?: number;
  spentOnUseOperations?: number;
  retunedMoneyOnProducts?: number;
  supplyOperations?: IOperation[];
  useOperations?: IOperation[];
  sellOperations?: IOperation[];
  visits?: IVisit[];
}

export type GetReportsAction = {
  type: typeof ReportsActionType.GET_REPORTS;
  payload: IReport;
};

export type ReportsAction = GetReportsAction;

const initialState: IReport = {};

const reducer = (state = initialState, action: ReportsAction): IReport => {
  switch (action.type) {
    case ReportsActionType.GET_REPORTS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;

export const getReports = (startDate: string, endDate: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/reports?startDate=${startDate}&endDate=${endDate}`);
    dispatch({ type: ReportsActionType.GET_REPORTS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};
