export function sendToGoogleAnalytics(duration, endpoint) {
  if (!window.dataLayer || !window.dataLayer.push) {
    return;
  }

  window.dataLayer.push({
    event: "xhrRequestCompleteHg",
    duration: duration,
    endpoint: endpoint
  });
}
