import axios from "axios";
import axiosService from "../../../axios-service";
import { createSuccessNotification, createErrorNotification } from "../notifications";
import { ThunkDispatchType } from "..";
import { IVisit } from "../visits";
import { IOperation } from "../operations";
import { RootState } from "../..";

export enum ClientActionType {
  GET_CLIENT = "GET_CLIENT",
  GET_CLIENTS = "GET_CLIENTS",
  ADD_CLIENT = "ADD_CLIENT",
  UPDATE_CLIENT = "UPDATE_CLIENT",
  CLEAR_CLIENTS = "CLEAR_CLIENTS"
}

export interface IClient {
  id: number;
  name: string;
  birth: string;
  phone: string;
  email: string | null;
  notes: string | null;
  archived: boolean;
  visits?: IVisit[];
  operations?: IOperation[];
}

export type GetClientAction = {
  type: typeof ClientActionType.GET_CLIENT;
  payload: IClient;
};

export type GetClientsAction = {
  type: typeof ClientActionType.GET_CLIENTS;
  payload: { count: number; clients: IClient[] };
};

export type AddClientAction = {
  type: typeof ClientActionType.ADD_CLIENT;
  payload: IClient;
};

export type UpdateClientAction = {
  type: typeof ClientActionType.UPDATE_CLIENT;
  payload: IClient;
};

export type ClearClientsAction = {
  type: typeof ClientActionType.CLEAR_CLIENTS;
};

export type ClientAction = GetClientAction | GetClientsAction | AddClientAction | UpdateClientAction | ClearClientsAction;

type ClientsState = { clients: { [k: number]: IClient }; count: number };
const initialState: ClientsState = { count: 0, clients: [] };

const reducer = (state = initialState, action: ClientAction): ClientsState => {
  switch (action.type) {
    case ClientActionType.CLEAR_CLIENTS:
      return { clients: [], count: 0 };
    case ClientActionType.GET_CLIENTS:
      return { clients: { ...mapArrayToObject(action.payload.clients, state) }, count: action.payload.count };
    case ClientActionType.GET_CLIENT:
    case ClientActionType.UPDATE_CLIENT:
      state.clients[action.payload.id] = action.payload;
      return { ...state };
    case ClientActionType.ADD_CLIENT:
      state.clients[action.payload.id] = action.payload;
      state.count = state.count + 1;
      return { ...state };
    default:
      return state;
  }
};

export default reducer;

const mapArrayToObject = (clientsArray: IClient[], map: ClientsState) => {
  const clientsObj = map.clients;
  clientsArray.forEach((client) => (clientsObj[client.id] = client));
  return clientsObj;
};

export const getClients = (page: number) => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  try {
    if (Object.values(getState().clientsState.clients).length >= (page + 1) * 20) {
      dispatch({ type: ClientActionType.CLEAR_CLIENTS });
    }
    const response = await axiosService.get(`/api/v2/clients?page=${page}`);
    dispatch({ type: ClientActionType.GET_CLIENTS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const getClient = (clientId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/clients/${clientId}`);
    dispatch({ type: ClientActionType.GET_CLIENT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addClient = (client: IClient, callBack: (savedClient: IClient) => void) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/clients", client);
    dispatch({ type: ClientActionType.ADD_CLIENT, payload: response.data });
    dispatch(createSuccessNotification("Клієнт успішно доданий"));
    callBack(response.data);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const updateClient = (client: IClient) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.patch(`/api/v2/clients/${client.id}`, client);
    dispatch({ type: ClientActionType.UPDATE_CLIENT, payload: response.data });
    dispatch(createSuccessNotification("Клієнт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const deleteClient = (clientId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.delete(`/api/v2/clients/${clientId}`);
    dispatch({ type: ClientActionType.UPDATE_CLIENT, payload: response.data });
    dispatch(createSuccessNotification("Клієнт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};
