import React from "react";
import { connect } from "react-redux";
import { RootState } from "./redux";
import LoginPage from "./components/structure/LoginPage";
import { getCurrentUserInfo } from "./redux/modules/auth";

interface LoginEnforcerProps {
  isLogged: boolean | null;
  getCurrentUserInfo?: () => void;
  children?: string | JSX.Element | JSX.Element[];
}

class LoginEnforcer extends React.Component<LoginEnforcerProps> {
  componentDidMount() {
    this.props.getCurrentUserInfo && this.props.getCurrentUserInfo();
  }

  render() {
    if (this.props.isLogged === false) {
      return <LoginPage />;
    } else if (this.props.isLogged === true) {
      return <>{this.props?.children}</>;
    } else {
      return <p>Перевіряємо дані авторизації</p>;
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return { isLogged: state.auth.isLogged };
};

export default connect(mapStateToProps, { getCurrentUserInfo })(LoginEnforcer);
