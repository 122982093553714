import React from "react";
import { connect } from "react-redux";
import { IVisit } from "../../redux/modules/visits/index";
import { createErrorNotification } from "../../redux/modules/notifications";
import { UseOperation, addUseOperationOnVisit, deleteUseOperationOnVisit } from "../../redux/modules/operations";
import { addServiceToVisit, removeServiceFromVisit } from "../../redux/modules/visits";
import { ISearchService, searchServices, clearSearch, ISearchProduct, searchProducts } from "../../redux/modules/search";
import { RootState } from "../../redux";
import { formatDate, formatPrice, getCurrentDateInISO } from "../../utils";

interface VisitFormProps {
  clientId?: number;
  clientName?: string;
  visit?: IVisit;
  services?: ISearchService[];
  products?: ISearchProduct[];
  searchServices?: (query: string) => void;
  searchProducts?: (query: string) => void;
  deleteUseOperationOnVisit?: (operationId: number) => void;
  addUseOperationOnVisit?: (operation: UseOperation) => void;
  clearSearch?: () => void;
  saveHandler?: (visit: Partial<IVisit>) => void;
  createErrorNotification?: (message: string) => void;
  addServiceToVisit?: (clientId: number, visitId: number) => void;
  removeServiceFromVisit?: (id: number) => void;
}

interface VisitFormState {
  product: ISearchProduct | null;
  amount: number | null;
  date: string;
  title: string;
  description: string;
  id: number;
  clientId: number;
  clientName: string;
  time: string;
  discount: number;
  rawDiscountValue: string;
  rawPrice: number;
  price: number;
  serviceQuery: string;
  productQuery: string;
}

class VisitForm extends React.Component<VisitFormProps, VisitFormState> {
  constructor(props: VisitFormProps) {
    super(props);
    this.state = { ...this.getStateObjectFromProps(props) };
  }

  getStateObjectFromProps = (props: VisitFormProps): VisitFormState => {
    if (!props.visit) {
      return {
        id: 0,
        date: getCurrentDateInISO(),
        description: "",
        title: "",
        clientId: props.clientId || 0,
        clientName: props.clientName || "",
        time: "",
        price: 0,
        discount: 0,
        rawDiscountValue: "0",
        rawPrice: 0,
        serviceQuery: "",
        productQuery: "",
        product: null,
        amount: null
      };
    }
    const { id, date, description, title, clientId, client, time, discount, services } = props.visit;
    const rawPrice = services.map((serviceDto) => serviceDto.service.price).reduce((s1, s2) => s1 + s2, 0);

    return {
      id,
      date,
      description,
      title,
      clientId,
      clientName: client.name,
      time,
      price: rawPrice - (rawPrice * discount) / 100,
      discount,
      rawDiscountValue: "" + discount,
      rawPrice,
      serviceQuery: "",
      productQuery: "",
      product: null,
      amount: null
    };
  };

  save = () => {
    if (!this.state.date || !this.state.title || !this.state.time) {
      this.props.createErrorNotification && this.props.createErrorNotification("Дата, час та короткий опис обов'язкові поля");
      return;
    }

    if (parseFloat(this.state.rawDiscountValue) !== this.state.discount) {
      this.props.createErrorNotification && this.props.createErrorNotification(`Знижка невірного формату ${this.state.rawDiscountValue}`);
      return;
    }
    this.props.saveHandler && this.props.saveHandler({ ...this.state, clientId: this.state.clientId });
  };

  removeAttachedService = (serviceOnVisitId: number) => {
    this.props.removeServiceFromVisit && this.props.removeServiceFromVisit(serviceOnVisitId);
  };

  renderAttachedServices = () => {
    return this.props?.visit?.services.map((serviceDto) => {
      return (
        <button type="button" onClick={() => this.removeAttachedService(serviceDto.id)} className="btn btn-outline-info">
          {serviceDto.service.name} - {serviceDto.service.price}
        </button>
      );
    });
  };

  renderOperations = () => {
    return this.props.visit?.operations.map((o) => {
      return (
        <button type="button" className="btn btn-outline-info" key={o.id} onClick={() => this.removeOperation(o.id)}>
          {o.amount}
          {o.product?.unitType === "piece" ? "шт" : "мл"} - {o?.product?.name}
        </button>
      );
    });
  };

  addAttachedServices = (serviceId: number) => {
    this.setState({ serviceQuery: "" });
    this.props.clearSearch && this.props.clearSearch();
    this.props.addServiceToVisit && this.props.visit && this.props.addServiceToVisit(serviceId, this.props.visit.id);
  };

  renderAvailableServices() {
    return (
      <>
        <div className="form-group">
          <label className="form-check-label">Пошук по назві послуги</label>
          <input className="form-control" type="text" onChange={this.applyServiceFilter} value={this.state.serviceQuery} />
        </div>
        <ul className="list-group">{this.renderServiceList()}</ul>
      </>
    );
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(event.target.value);
    this.setState({ amount });
  };

  cancel = () => {
    this.setState({
      product: null,
      amount: null
    });
  };

  handleUse = () => {
    if (!this.state.product || !this.state.amount || !this.props.visit) {
      return;
    }
    this.props.addUseOperationOnVisit &&
      this.props.addUseOperationOnVisit({
        visitId: this.props.visit?.id,
        amount: this.state.amount,
        productId: this.state.product.id,
        date: this.props.visit?.date
      });
    this.setState({
      product: null,
      amount: null,
      productQuery: ""
    });
    this.props.clearSearch && this.props.clearSearch();
  };

  renderAvailableProducts() {
    if (this.state.product) {
      return (
        <>
          <div className="form-group">
            <p>Списати товар:</p>
            <button className="btn btn-outline-info">{this.state.product.name}</button>
            <br />
            <label htmlFor="formGroupExampleInput">Кількість товару, {this.state.product.unitType}</label>
            <input
              type="number"
              className="form-control"
              onChange={this.handleAmountChange}
              id="formGroupExampleInput"
              value={this.state.amount || ""}
              required
            />
            <button className="btn btn-outline-danger" onClick={this.cancel}>
              Скасувати
            </button>
            <button className="btn btn-outline-primary" onClick={this.handleUse}>
              Списати
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="form-group">
          <p>Списати товар:</p>
          <label className="form-check-label">Пошук по назві продукту</label>
          <input className="form-control" type="text" onChange={this.applyProductFilter} value={this.state.productQuery} />
        </div>
        <ul className="list-group">{this.renderProductList()}</ul>
      </>
    );
  }

  addProductForUse(product: ISearchProduct) {
    this.setState({ product });
  }

  renderProductList() {
    if (!this.props.products) {
      return null;
    }
    return this.props.products
      .filter((p) => p.amount)
      .map((p) => {
        return (
          <li className="list-group-item" key={p.id} onClick={() => this.addProductForUse(p)}>
            {p.name}
          </li>
        );
      });
  }

  removeOperation = (operationId: number) => {
    this.props.deleteUseOperationOnVisit && this.props.deleteUseOperationOnVisit(operationId);
  };

  applyProductFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const productQuery = e.target.value;
    if (productQuery && productQuery.length > 2) {
      this.props.searchProducts && this.props.searchProducts(productQuery);
    } else {
      this.props.clearSearch && this.props.clearSearch();
    }
    this.setState({ productQuery });
  };

  applyServiceFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const serviceQuery = e.target.value;
    if (serviceQuery && serviceQuery.length > 2) {
      this.props.searchServices && this.props.searchServices(serviceQuery);
    } else {
      this.props.clearSearch && this.props.clearSearch();
    }
    this.setState({ serviceQuery });
  };

  renderServiceList() {
    if (!this.props.services) {
      return null;
    }
    return this.props.services.map((s) => {
      return (
        <li className="list-group-item" key={s.id} onClick={() => this.addAttachedServices(s.id)}>
          {s.name} - {s.price}
        </li>
      );
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, key: keyof IVisit) => {
    const stateObj = {} as VisitFormState;
    // @ts-ignore
    stateObj[key] = event.target.value as unknown as undefined;
    this.setState(stateObj);
  };

  handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rawDiscountValue = event.target.value;
    const discount = parseFloat(rawDiscountValue);

    if (rawDiscountValue.indexOf(",") > -1) {
      rawDiscountValue = rawDiscountValue.replaceAll(",", ".");
    }

    if (isNaN(discount)) {
      this.setState({ rawDiscountValue });
      this.props.createErrorNotification && this.props.createErrorNotification(`Знижка невірного формату ${rawDiscountValue}`);
      return;
    }
    const price = this.getPriceWithDiscount(this.state.rawPrice, discount);
    this.setState({
      rawDiscountValue,
      price,
      discount
    });
  };

  getPriceWithDiscount = (rawPrice: number, discount: number): number => {
    return (rawPrice * (100 - discount)) / 100;
  };

  renderProducts = () => {
    if (!this.state.id) {
      return (
        <div className="form-group available-services-container">
          <p>Щоб додати використані товари, збережіть візит</p>
        </div>
      );
    }
    return (
      <>
        <div className="form-group available-services-container">
          <p>Використані товари:</p>
          {this.renderOperations()}
        </div>
        <div className="form-group available-services-container">{this.renderAvailableProducts()}</div>
      </>
    );
  };

  componentDidUpdate(prevProps: Readonly<VisitFormProps>, prevState: Readonly<VisitFormState>) {
    if (prevProps.visit?.services.length !== this.props.visit?.services.length) {
      this.setState({ ...this.getStateObjectFromProps(this.props) });
    }
    if (prevProps.visit?.operations.length !== this.props.visit?.operations.length) {
      this.setState({ ...this.getStateObjectFromProps(this.props) });
    }
  }

  renderServicesSection() {
    if (!this.state.id) {
      return (
        <div className="form-group available-services-container">
          <p>Щоб додати послуги, збережіть візит</p>
        </div>
      );
    }
    return (
      <>
        <div className="form-group available-services-container">
          <p>Послуги у візиті:</p>
          {this.renderAttachedServices()}
          <br />
        </div>
        <div className="form-group available-services-container">
          <p>Доступні послуги:</p>
          {this.renderAvailableServices()}
        </div>
      </>
    );
  }

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Клієнт</label>
          <input type="text" className="form-control" id="formGroupExampleInput" value={this.state.clientName} readOnly required />
          <input type="hidden" className="form-control" value={this.state.clientId} />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Дата візиту</label>
          <input
            type="date"
            className="form-control"
            onChange={(event) => this.handleChange(event, "date")}
            required
            id="formGroupExampleInput2"
            value={formatDate(this.state.date)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Час візиту</label>
          <input
            type="time"
            className="form-control"
            onChange={(event) => this.handleChange(event, "time")}
            id="formGroupExampleInput2"
            value={this.state.time}
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput3">Короткий опис</label>
          <input
            type="text"
            className="form-control"
            onChange={(event) => this.handleChange(event, "title")}
            required
            id="formGroupExampleInput3"
            value={this.state.title}
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput3">Вартість без знижки, грн</label>
          <input type="number" className="form-control-plaintext" readOnly id="formGroupExampleInput3" value={this.state.rawPrice} />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput3">Знижка, %</label>
          <input type="text" className="form-control" onChange={this.handleDiscountChange} id="formGroupExampleInput3" value={this.state.rawDiscountValue} />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput3">Вартість зі знижкою, грн</label>
          <p>{formatPrice(this.state.price)}</p>
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput8">Нотатки</label>
          <br />
          <textarea style={{ minWidth: "100%" }} onChange={(event) => this.handleChange(event, "description")} value={this.state.description || ""} />
        </div>
        {this.renderProducts()}
        {this.renderServicesSection()}
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Зберегти
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { services: state.searchState.services, products: state.searchState.products };
};

export default connect(mapStateToProps, {
  createErrorNotification,
  removeServiceFromVisit,
  addServiceToVisit,
  searchServices,
  clearSearch,
  deleteUseOperationOnVisit,
  searchProducts,
  addUseOperationOnVisit
})(VisitForm);
