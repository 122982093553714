import axios from "axios";
import axiosService from "../../../axios-service";
import { ThunkDispatchType } from "..";
import { createErrorNotification, createSuccessNotification } from "../notifications";
import { ClientActionType, IClient } from "../clients";
import { IService } from "../services";
import { IOperation } from "../operations";

export enum VisitActionType {
  GET_VISIT = "GET_VISIT",
  GET_VISITS = "GET_VISITS",
  DELETE_VISIT = "DELETE_VISIT",
  ADD_VISIT = "ADD_VISIT",
  UPDATE_VISIT = "UPDATE_VISIT"
}

export interface IVisit {
  id: number;
  date: string;
  time: string;
  description: string;
  title: string;
  clientId: number;
  discount: number;
  services: ServiceDto[];
  operations: IOperation[];
  client: IClient;
}

export interface ServiceDto {
  service: IService;
  serviceId: number;
  visitId: number;
  id: number;
}

export type GetVisitAction = {
  type: typeof VisitActionType.GET_VISIT;
  payload: IVisit;
};

export type DeleteVisitAction = {
  type: typeof VisitActionType.DELETE_VISIT;
  payload: number;
};

export type GetVisitsAction = {
  type: typeof VisitActionType.GET_VISITS;
  payload: IVisit[];
};

export type AddVisitAction = {
  type: typeof VisitActionType.ADD_VISIT;
  payload: IVisit;
};

export type UpdateVisitAction = {
  type: typeof VisitActionType.UPDATE_VISIT;
  payload: IVisit;
};

export type VisitAction = GetVisitAction | GetVisitsAction | AddVisitAction | UpdateVisitAction | DeleteVisitAction;

const initialState: IVisit[] = [];

const reducer = (state = initialState, action: VisitAction): IVisit[] => {
  switch (action.type) {
    case VisitActionType.DELETE_VISIT:
      return [...state.filter((v) => v.id !== action.payload)];
    case VisitActionType.GET_VISITS:
      return [...action.payload];
    case VisitActionType.GET_VISIT:
    case VisitActionType.UPDATE_VISIT:
      return [...state.filter((v) => v.id !== action.payload.id), action.payload];
    case VisitActionType.UPDATE_VISIT:
      return [...state.filter((v) => v.id !== action.payload.id), action.payload];
    default:
      return state;
  }
};

export default reducer;

export const getVisits = (clientId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/visits?clientId=${clientId}`);
    dispatch({ type: VisitActionType.GET_VISITS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const getVisit = (visitId: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/visits/${visitId}`);
    dispatch({ type: VisitActionType.GET_VISIT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addVisit = (visit: Partial<IVisit>, callBack: (v: IVisit) => void) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/visits", visit);
    callBack(response.data);
    dispatch({ type: VisitActionType.ADD_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Візит успішно створений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збереженні даних: ${cause}`));
    }
  }
};

export const updateVisit = (visit: Partial<IVisit>) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.patch(`/api/v2/visits/${visit.id}`, visit);
    dispatch({ type: VisitActionType.UPDATE_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Візит успішно збережений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const addServiceToVisit = (serviceId: number, visitId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/service-on-visit/", { serviceId, visitId });
    dispatch({ type: VisitActionType.UPDATE_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Візит успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const removeServiceFromVisit = (serviceToVisitId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.delete(`/api/v2/service-on-visit/${serviceToVisitId}`);
    dispatch({ type: VisitActionType.UPDATE_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Візит успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const deleteVisit = (visitId: number, client: IClient) => async (dispatch: ThunkDispatchType) => {
  try {
    if (!client.visits || !visitId) {
      return;
    }
    await axiosService.delete(`/api/v2/visits/${visitId}`);
    client.visits = [...client.visits.filter((v) => v.id !== visitId)];
    dispatch({ type: VisitActionType.DELETE_VISIT, payload: visitId });
    dispatch({ type: ClientActionType.UPDATE_CLIENT, payload: client });
    dispatch(createSuccessNotification("Візит успішно видалений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};
