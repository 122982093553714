import React from "react";
import { connect } from "react-redux";
import { IProduct } from "../../redux/modules/products";
import { createErrorNotification } from "../../redux/modules/notifications";
import { withRouter } from "../../hooks/withRouter";
import { RootState } from "../../redux";

interface ProductFormProps {
  product?: IProduct;
  saveHandler?: (product: IProduct) => void;
  createErrorNotification?: (message: string) => void;
}

interface ProductFormState extends Partial<IProduct> {}

class ProductForm extends React.Component<ProductFormProps, ProductFormState> {
  constructor(props: ProductFormProps) {
    super(props);
    const { id, name, notes, basePrice, sellPrice, amount, unitType } = this.props.product || {
      _id: "",
      name: "",
      notes: "",
      basePrice: 0,
      sellPrice: 0,
      amount: 0,
      unitType: "piece"
    };
    this.state = {
      id,
      name,
      notes,
      basePrice,
      sellPrice,
      amount,
      unitType
    };
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof IProduct
  ) => {
    const stateObj: ProductFormState = {};
    stateObj[key] = event.target.value as unknown as undefined;
    this.setState(stateObj);
  };

  save = () => {
    if (!this.state.name || !this.state.basePrice || !this.state.sellPrice || !this.state.basePrice || !this.state.unitType) {
      this.props.createErrorNotification && this.props.createErrorNotification("Заповніть обов'язкові поля");
      return;
    }
    const newState = { ...this.state };
    this.props.saveHandler && this.props.saveHandler(newState as IProduct);
  };

  componentDidUpdate(prevProps: Readonly<ProductFormProps>, prevState: Readonly<ProductFormState>, snapshot?: any): void {
    if (this.props.product?.amount !== prevProps.product?.amount) {
      this.setState({ amount: this.props.product?.amount });
    }
  }

  render() {
    const { name, notes, basePrice, sellPrice, amount, unitType } = this.state;
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Назва Товару</label>
          <input type="text" className="form-control" onChange={(event) => this.handleChange(event, "name")} id="formGroupExampleInput" value={name} required />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Одиниця обліку товару</label>
          <select className="form-select" onChange={(event) => this.handleChange(event, "unitType")} defaultValue={unitType}>
            <option value="piece">Штука</option>
            <option value="ml">Мілілітр</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Закупівельна ціна за одиницю товару</label>
          <input
            type="number"
            className="form-control"
            onChange={(event) => this.handleChange(event, "basePrice")}
            id="formGroupExampleInput"
            value={basePrice}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Ціна на продаж за одиницю товару</label>
          <input
            type="number"
            className="form-control"
            onChange={(event) => this.handleChange(event, "sellPrice")}
            id="formGroupExampleInput"
            value={sellPrice}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Кількість</label>
          <input
            type="number"
            className="form-control-plaintext"
            onChange={(event) => this.handleChange(event, "amount")}
            id="formGroupExampleInput"
            value={amount}
            required
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput8">Нотатки</label>
          <br />
          <textarea style={{ minWidth: "100%" }} onChange={(event) => this.handleChange(event, "notes")} value={notes || ""} />
        </div>
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Зберегти
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { products: Object.values(state.productsState.products) };
};

export default withRouter(connect(mapStateToProps, { createErrorNotification })(ProductForm));
