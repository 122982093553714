import axios from "axios";
import { SpinnerTypeActionType } from "./redux/modules/spinner";
import storeHolder from "./store-holder";
import { sendToGoogleAnalytics } from "./track-analytics";

const instance = axios.create();

let requestIterator = 0;

instance.interceptors.request.use(
  function (config) {
    // @ts-ignore
    config.metadata = { startTime: new Date() };
    requestIterator++;
    if (storeHolder.store) {
      document.body.classList.add("overflow");
      storeHolder.store.dispatch({ type: SpinnerTypeActionType.SHOW_SPINNER });
    }
    return config;
  },
  function (error) {
    requestIterator--;
    if (requestIterator === 0) {
      if (storeHolder.store) {
        document.body.classList.remove("overflow");
        storeHolder.store.dispatch({ type: SpinnerTypeActionType.HIDE_SPINNER });
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // @ts-ignore
    const duration = new Date() - response.config.metadata.startTime;
    requestIterator--;
    if (requestIterator === 0) {
      if (storeHolder.store) {
        document.body.classList.remove("overflow");
        storeHolder.store.dispatch({ type: SpinnerTypeActionType.HIDE_SPINNER });
      }
    }

    sendToGoogleAnalytics(duration, response.config.url);
    return response;
  },
  function (error) {
    requestIterator--;
    if (requestIterator === 0) {
      if (storeHolder.store) {
        document.body.classList.remove("overflow");
        storeHolder.store.dispatch({ type: SpinnerTypeActionType.HIDE_SPINNER });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
