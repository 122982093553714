import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IService, deleteService, getServices } from "../../../redux/modules/services";
import { RootState } from "../../../redux";
import TypeaheadSearch, { SearchType } from "../../TypeaheadSearch";

interface ServiceListProps {
  services?: IService[];
  deleteService?: (serviceId: number) => void;
  getServices?: (page: number) => Promise<void>;
  count?: number;
}

type ServiceListState = {
  hideArchived: boolean;
  page: number;
};

class ServiceListPage extends React.Component<ServiceListProps, ServiceListState> {
  constructor(props: ServiceListProps) {
    super(props);
    this.state = { hideArchived: true, page: 0 };
  }

  renderServices() {
    if (!this.props.services) {
      return <p>Триває пошук...</p>;
    }
    return this.props.services
      .sort((c1, c2) => (c1.name < c2.name ? -1 : 1))
      .map((service) => {
        if (this.state.hideArchived && service.archived) {
          return null;
        }

        return (
          <li className="list-group-item" key={service.id}>
            <div className="row">
              <div className="col-8">
                <Link to={`/service/${service.id}`}>{service.name}</Link>
              </div>
              <div className="col-4 text-right">{this.renderButtons(service)}</div>
            </div>
          </li>
        );
      });
  }

  renderButtons(service: IService) {
    if (!service.archived) {
      return (
        <button type="button" className="btn btn-outline-danger" onClick={() => this.archiveService(service)}>
          X
        </button>
      );
    }
    return (
      <button type="button" className="btn btn-outline-danger">
        Не Активний
      </button>
    );
  }

  render() {
    return (
      <div>
        <h3>Послуги:</h3>
        <Link to="/new-service">
          <button type="button" className="btn btn-outline-primary btn-lg btn-block">
            Додати Послугу
          </button>
        </Link>
        <form>
          <TypeaheadSearch searchType={SearchType.SERVICES} />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={this.state.hideArchived}
              onChange={() => this.toggleArchived()}
              id="flexCheckChecked"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Сховати архівні послугу
            </label>
          </div>
          <br />
        </form>
        <ul className="list-group">{this.renderServices()}</ul>
        <br></br>
        {this.renderLoadMoreButton()}
        <br></br>
      </div>
    );
  }

  renderLoadMoreButton = () => {
    if (this.props.services && this.props.count && this.props.services.length && this.props.services.length < this.props.count) {
      return (
        <button className="btn btn-outline-primary" onClick={() => this.loadMore()}>
          Завантажити ще
        </button>
      );
    }
  };

  loadMore = () => {
    const page = this.state.page + 1;
    this.setState({ page });
    this.props.getServices && this.props.getServices(page);
  };

  toggleArchived = () => {
    this.setState({ hideArchived: !this.state.hideArchived });
  };

  archiveService = (service: IService) => {
    this.props.deleteService && this.props.deleteService(service.id);
  };

  componentDidMount() {
    this.props.getServices && this.props.getServices(0);
  }
}

const mapStateToProps = (state: RootState) => {
  return { services: Object.values(state.servicesState.services), count: state.servicesState.count };
};

export default connect(mapStateToProps, { getServices, deleteService })(ServiceListPage);
