import axios from "axios";
import axiosService from "../../../axios-service";
import { createSuccessNotification, createErrorNotification } from "../notifications";
import { ThunkDispatchType } from "..";
import { IOperation } from "../operations";
import { RootState } from "../..";

export enum ProductActionType {
  CLEAR_PRODUCTS = "CLEAR_PRODUCTS",
  GET_PRODUCT = "GET_PRODUCT",
  GET_PRODUCTS = "GET_PRODUCTS",
  ADD_PRODUCT = "ADD_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT"
}

export interface IProduct {
  id: number;
  name: string;
  notes: string | null;
  basePrice: number;
  sellPrice: number;
  amount: number;
  unitType: string;
  archived: boolean;
  operations?: IOperation[];
}

export type GetProductAction = {
  type: typeof ProductActionType.GET_PRODUCT;
  payload: IProduct;
};

export type ClearProductsAction = {
  type: typeof ProductActionType.CLEAR_PRODUCTS;
};

export type GetProductsAction = {
  type: typeof ProductActionType.GET_PRODUCTS;
  payload: { count: number; products: IProduct[] };
};

export type AddProductAction = {
  type: typeof ProductActionType.ADD_PRODUCT;
  payload: IProduct;
};

export type UpdateProductAction = {
  type: typeof ProductActionType.UPDATE_PRODUCT;
  payload: IProduct;
};

export type ProductAction = GetProductAction | GetProductsAction | AddProductAction | UpdateProductAction | ClearProductsAction;

type ProductsState = { products: { [k: number]: IProduct }; count: number };
const initialState: ProductsState = { count: 0, products: [] };

const reducer = (state = initialState, action: ProductAction): ProductsState => {
  switch (action.type) {
    case ProductActionType.CLEAR_PRODUCTS:
      return { products: [], count: 0 };
    case ProductActionType.GET_PRODUCTS:
      return { products: { ...mapArrayToObject(action.payload.products, state) }, count: action.payload.count };
    case ProductActionType.UPDATE_PRODUCT:
    case ProductActionType.GET_PRODUCT:
      state.products[action.payload.id] = action.payload;
      return { ...state };
    case ProductActionType.ADD_PRODUCT:
      state.products[action.payload.id] = action.payload;
      state.count = state.count + 1;
      return { ...state };
    default:
      return state;
  }
};

export default reducer;

const mapArrayToObject = (productsArray: IProduct[], map: ProductsState) => {
  const productsObj = map.products;
  productsArray.forEach((product) => (productsObj[product.id] = product));
  return productsObj;
};

export const getProducts = (page: number) => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  try {
    if (Object.values(getState().productsState.products).length >= (page + 1) * 20) {
      dispatch({ type: ProductActionType.CLEAR_PRODUCTS });
    }
    const response = await axiosService.get(`/api/v2/products?page=${page}`);
    dispatch({ type: ProductActionType.GET_PRODUCTS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const getProduct = (productId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/products/${productId}`);
    dispatch({ type: ProductActionType.GET_PRODUCT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addProduct = (product: IProduct) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/products", product);
    dispatch({ type: ProductActionType.ADD_PRODUCT, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно доданий"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const updateProduct = (product: IProduct) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.patch(`/api/v2/products/${product.id}`, product);
    dispatch({ type: ProductActionType.UPDATE_PRODUCT, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const deleteProduct = (productId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.delete(`/api/v2/products/${productId}`);
    dispatch({ type: ProductActionType.UPDATE_PRODUCT, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};
