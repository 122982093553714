import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import Spinner from "./components/Spinner";
import Dashboard from "./components/structure/Dashboard";
import ClientListPage from "./components/structure/ClientListPage";
import CreateClientPage from "./components/structure/CreateClientPage";
import ClientPage from "./components/structure/ClientPage";
import CreateVisitPage from "./components/structure/CreateVisitPage";
import VisitPage from "./components/structure/VisitPage";
import BrowserRouter from "./components/BrowserRouter";
import store from "./redux";
import NotificationList from "./components/NotificationList";
import history from "./history";
import LoginEnforcer from "./LoginEnforcer";
import ProductListPage from "./components/structure/ProductListPage";
import CreateProductPage from "./components/structure/CreateProductPage";
import ProductPage from "./components/structure/ProductPage";
import ServiceListPage from "./components/structure/ServiceListPage";
import CreateServicePage from "./components/structure/CreateServicePage";
import ServicePage from "./components/structure/ServicePage";
import ReportsPage from "./components/structure/ReportsPage";
import storeHolder from "./store-holder";
import "./App.css";

storeHolder.store = store;

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Spinner />
        <BrowserRouter history={history}>
          <NotificationList />
          <nav className="nav">
            <Link to="/" className="nav-link">
              Домівка
            </Link>
          </nav>
          <LoginEnforcer>
            <div className="container">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/new-client" element={<CreateClientPage />} />
                <Route path="/client/:id" element={<ClientPage />} />
                <Route path="/visit/:id" element={<VisitPage />} />
                <Route path="/new-visit" element={<CreateVisitPage />} />
                <Route path="/client-list" element={<ClientListPage />} />
                <Route path="/product-list" element={<ProductListPage />} />
                <Route path="/new-product" element={<CreateProductPage />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/service-list" element={<ServiceListPage />} />
                <Route path="/new-service" element={<CreateServicePage />} />
                <Route path="/service/:id" element={<ServicePage />} />
                <Route path="/reports" element={<ReportsPage />} />
              </Routes>
            </div>
          </LoginEnforcer>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
