import React from "react";
import { connect } from "react-redux";
import { getService, IService, updateService } from "../../../redux/modules/services";
import ServiceForm from "../../ServiceForm";
import { withRouter } from "../../../hooks/withRouter";
import { RootState } from "../../../redux";

interface ServicePageProps {
  services?: IService[];
  router?: { params?: { id?: string }; location?: { search?: string } };
  getService?: (serviceId: number) => void;
  updateService?: (service: IService) => void;
}

class ServicePage extends React.Component<ServicePageProps> {
  constructor(props: ServicePageProps) {
    super(props);
  }

  componentDidMount() {
    const id = this.props?.router?.params?.id;
    if (!id) {
      return;
    }
    this.props.getService && this.props.getService(+id);
  }

  renderServiceData() {
    const pageId = this.props?.router?.params?.id;
    if (!this.props.services || !pageId) {
      return;
    }
    const service = this.props.services.find(({ id }) => id === +pageId);
    if (!service) {
      return <p>Service with id: {pageId}</p>;
    }
    return <ServiceForm service={service} saveHandler={this.props.updateService} />;
  }

  render() {
    return (
      <div>
        <div>{this.renderServices()}</div>
      </div>
    );
  }

  renderServices() {
    if (this.props.services && Object.keys(this.props.services).length) {
      return this.renderServiceData();
    }
    return <p>Loading service with id: {this.props?.router?.params?.id}</p>;
  }
}

const mapStateToProps = (state: RootState) => {
  return { services: Object.values(state.servicesState.services) };
};

export default withRouter(
  connect(mapStateToProps, {
    getService,
    updateService
  })(ServicePage)
);
