import { Dispatch } from "redux";
import { GlobalActionType } from "..";

export enum SpinnerTypeActionType {
  SHOW_SPINNER = "SHOW_SPINNER",
  HIDE_SPINNER = "HIDE_SPINNER"
}

export type ShowSpinnerAction = {
  type: typeof SpinnerTypeActionType.SHOW_SPINNER;
};

export type HideSpinnerAction = {
  type: typeof SpinnerTypeActionType.HIDE_SPINNER;
};

export type SpinnerAction = ShowSpinnerAction | HideSpinnerAction;

const initialState: boolean = false;

const reducer = (state = initialState, action: SpinnerAction): boolean => {
  switch (action.type) {
    case SpinnerTypeActionType.SHOW_SPINNER:
      return true;
    case SpinnerTypeActionType.HIDE_SPINNER:
      return false;
    default:
      return state;
  }
};

export default reducer;

export const showSpinner = () => async (dispatch: Dispatch<GlobalActionType>) => {
  return dispatch({
    type: SpinnerTypeActionType.SHOW_SPINNER
  });
};

export const hideSpinner = () => async (dispatch: Dispatch<GlobalActionType>) => {
  return dispatch({
    type: SpinnerTypeActionType.HIDE_SPINNER
  });
};
