import React from "react";
import { connect } from "react-redux";
import { login, register } from "../../../redux/modules/auth";
import { createErrorNotification } from "../../../redux/modules/notifications";

interface LoginPageProps {
  login?: (login: string, password: string) => void;
  register?: (login: string, password: string) => void;
  createErrorNotification?: (message: string) => void;
}

interface LoginPageState {
  password: string;
  login: string;
}

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    this.state = { password: "", login: "" };
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value.toLowerCase();
    if (field === "password") {
      this.setState({ password: value });
    } else if (field === "login") {
      this.setState({ login: value });
    }
  };

  handleSubmit = () => {
    if (!this.state.login || !this.state.password) {
      this.props.createErrorNotification && this.props.createErrorNotification("Пароль і логін не можуть бути порожніми");
      return;
    }
    this.props.login && this.props.login(this.state.login, this.state.password);
  };

  handleRegister = () => {
    this.props.register && this.props.register(this.state.login, this.state.password);
  };

  render() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">
          <label htmlFor="formGroupExampleInput" className="form-label">
            Логін
          </label>
          <input onChange={(event) => this.handleChange(event, "login")} type="text" className="form-control" id="formGroupExampleInput" />
        </div>
        <div className="mb-3">
          <label htmlFor="formGroupExampleInput2" className="form-label">
            Пароль
          </label>
          <input onChange={(event) => this.handleChange(event, "password")} type="password" className="form-control" id="formGroupExampleInput2" />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-outline-primary" onClick={this.handleSubmit}>
            Увійти
          </button>
        </div>
        {/* <div className="col-12">
          <br></br>
          <button type="submit" className="btn btn-outline-primary" onClick={this.handleRegister}>
            Зареєеструватись
          </button>
        </div> */}
      </form>
    );
  }
}

export default connect(null, { login, register, createErrorNotification })(LoginPage);
