import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getVisit, IVisit, updateVisit } from "../../../redux/modules/visits";
import { getServices, IService } from "../../../redux/modules/services";
import VisitForm from "../../VisitForm";
import { withRouter } from "../../../hooks/withRouter";
import { RootState } from "../../../redux";

interface VisitPageProps {
  visits: IVisit[];
  services: IService[];
  router?: { params?: { id?: string } };
  updateVisit?: (visit: Partial<IVisit>) => Promise<void>;
  getVisit?: (visitId: string) => void;
  getServices?: (page: number) => void;
}

class VisitPage extends React.Component<VisitPageProps> {
  render() {
    const pageId = this.props?.router?.params?.id;
    if (!pageId) {
      return;
    }
    const visit = this.props.visits.find((v) => v.id === +pageId);
    if (!visit) {
      return <p>Visit with id: {pageId}</p>;
    }
    return (
      <div>
        <Link to={`/client/${visit.clientId}?tab=visits`} className="nav-link">
          Всі візити клієнта
        </Link>
        <VisitForm visit={visit} saveHandler={this.props.updateVisit} />
      </div>
    );
  }

  componentDidMount() {
    const visitId = this.props?.router?.params?.id;
    if (!visitId || !this.props.getVisit) {
      return;
    }
    this.props.getVisit(visitId);
    this.props.getServices && this.props.getServices(0);
  }
}

const mapStateToProps = (state: RootState) => {
  return { visits: state.visits, services: Object.values(state.servicesState.services) };
};

export default withRouter(connect(mapStateToProps, { getVisit, updateVisit, getServices })(VisitPage));
