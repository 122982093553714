import axios from "axios";
import axiosService from "../../../axios-service";
import { createSuccessNotification, createErrorNotification } from "../notifications";
import { ThunkDispatchType } from "..";
import { RootState } from "../..";

export enum ServiceActionType {
  CLEAR_SERVICES = "CLEAR_SERVICES",
  GET_SERVICE = "GET_SERVICE",
  GET_SERVICES = "GET_SERVICES",
  ADD_SERVICE = "ADD_SERVICE",
  UPDATE_SERVICE = "UPDATE_SERVICE"
}

export interface IService {
  id: number;
  name: string;
  price: number;
  notes: string;
  archived: boolean;
}

export type GetServiceAction = {
  type: typeof ServiceActionType.GET_SERVICE;
  payload: IService;
};

export type ClearServicesAction = {
  type: typeof ServiceActionType.CLEAR_SERVICES;
};

export type GetServicesAction = {
  type: typeof ServiceActionType.GET_SERVICES;
  payload: { services: IService[]; count: number };
};

export type AddServiceAction = {
  type: typeof ServiceActionType.ADD_SERVICE;
  payload: IService;
};

export type UpdateServiceAction = {
  type: typeof ServiceActionType.UPDATE_SERVICE;
  payload: IService;
};

export type ServiceAction = GetServiceAction | GetServicesAction | AddServiceAction | UpdateServiceAction | ClearServicesAction;

type ServicesState = { services: { [k: number]: IService }; count: number };
const initialState: ServicesState = { count: 0, services: [] };

const reducer = (state = initialState, action: ServiceAction): ServicesState => {
  switch (action.type) {
    case ServiceActionType.CLEAR_SERVICES:
      return { services: [], count: 0 };
    case ServiceActionType.GET_SERVICES:
      return { services: { ...mapArrayToObject(action.payload.services, state) }, count: action.payload.count };
    case ServiceActionType.UPDATE_SERVICE:
    case ServiceActionType.GET_SERVICE:
      state.services[action.payload.id] = action.payload;
      return { ...state };
    case ServiceActionType.ADD_SERVICE:
      state.services[action.payload.id] = action.payload;
      state.count = state.count + 1;
      return { ...state };
    default:
      return state;
  }
};

export default reducer;

const mapArrayToObject = (servicesArray: IService[], map: ServicesState) => {
  const servicesObj = map.services;
  servicesArray.forEach((service) => (servicesObj[service.id] = service));
  return servicesObj;
};

export const getServices = (page: number) => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  try {
    if (Object.values(getState().servicesState.services).length >= (page + 1) * 20) {
      dispatch({ type: ServiceActionType.CLEAR_SERVICES });
    }
    const response = await axiosService.get(`/api/v2/services?page=${page}`);
    dispatch({ type: ServiceActionType.GET_SERVICES, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const getService = (serviceId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/services/${serviceId}`);
    dispatch({ type: ServiceActionType.GET_SERVICE, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addService = (service: IService) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/services", service);
    dispatch({ type: ServiceActionType.ADD_SERVICE, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно доданий"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const updateService = (service: IService) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.patch(`/api/v2/services/${service.id}`, service);
    dispatch({ type: ServiceActionType.UPDATE_SERVICE, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};

export const deleteService = (serviceId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.delete(`/api/v2/services/${serviceId}`);
    dispatch({ type: ServiceActionType.UPDATE_SERVICE, payload: response.data });
    dispatch(createSuccessNotification("Продукт успішно оновлений"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при збережені даних: ${cause}`));
    }
  }
};
