import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getClients, IClient, updateClient, deleteClient } from "../../../redux/modules/clients";
import { RootState } from "../../../redux";
import TypeaheadSearch, { SearchType } from "../../TypeaheadSearch";

interface ClientListProps {
  clients?: IClient[];
  count?: number;
  updateClient?: (client: IClient) => void;
  deleteClient?: (clientId: number) => void;
  getClients?: (page: number) => Promise<void>;
}

type ClientListState = {
  hideArchived: boolean;
  page: number;
  showLoadMore: boolean;
};

class ClientListPage extends React.Component<ClientListProps, ClientListState> {
  constructor(props: ClientListProps) {
    super(props);
    this.state = { hideArchived: true, page: 0, showLoadMore: true };
  }

  loadMore = () => {
    const page = this.state.page + 1;
    this.setState({ page });
    this.props.getClients && this.props.getClients(page);
  };

  renderClients() {
    if (!this.props.clients) {
      return <p>Триває пошук...</p>;
    }
    return this.props.clients
      .sort((c1, c2) => {
        return c1.name.localeCompare(c2.name);
      })
      .map((client) => {
        if (this.state.hideArchived && client.archived) {
          return null;
        }

        return (
          <li className="list-group-item" key={client.id}>
            <div className="row">
              <div className="col-8">
                <Link to={`/client/${client.id}`}>{client.name}</Link>
              </div>
              <div className="col-4 text-right">{this.renderButtons(client)}</div>
            </div>
          </li>
        );
      });
  }

  renderButtons(client: IClient) {
    if (!client.archived) {
      return (
        <button type="button" className="btn btn-outline-danger" onClick={() => this.archiveClient(client)}>
          X
        </button>
      );
    }
    return (
      <button type="button" className="btn btn-outline-danger">
        Не Активний
      </button>
    );
  }

  render() {
    return (
      <div>
        <h3>Клієнти:</h3>
        <Link to="/new-client">
          <button type="button" className="btn btn-outline-primary btn-lg btn-block">
            Додати Клієнта
          </button>
        </Link>
        <form>
          <TypeaheadSearch searchType={SearchType.CLIENTS} />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={this.state.hideArchived}
              onChange={() => this.toggleArchived()}
              id="flexCheckChecked"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Сховати архівних клієнтів
            </label>
          </div>
          <br />
        </form>

        <ul className="list-group">{this.renderClients()}</ul>
        <br></br>
        {this.renderLoadMoreButton()}
        <br></br>
      </div>
    );
  }

  renderLoadMoreButton = () => {
    if (this.props.clients && this.props.count && this.props.clients.length && this.props.clients.length < this.props.count) {
      return (
        <button className="btn btn-outline-primary" onClick={() => this.loadMore()}>
          Завантажити ще
        </button>
      );
    }
  };

  toggleArchived = () => {
    this.setState({ hideArchived: !this.state.hideArchived });
  };

  archiveClient = (client: IClient) => {
    this.props.deleteClient && this.props.deleteClient(client.id);
  };

  unarchiveClient = (client: IClient) => {
    client.archived = false;
    this.props.updateClient && this.props.updateClient(client);
  };

  componentDidMount() {
    this.props.getClients && this.props.getClients(this.state.page);
  }
}

const mapStateToProps = (state: RootState) => {
  return { clients: Object.values(state.clientsState.clients), count: state.clientsState.count };
};

export default connect(mapStateToProps, { getClients, updateClient, deleteClient })(ClientListPage);
