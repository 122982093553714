import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import clientReducer from "./modules/clients";
import visitsReducer from "./modules/visits";
import notificationsReducer from "./modules/notifications";
import authReducer from "./modules/auth";
import productsReducer from "./modules/products";
import servicesReducer from "./modules/services";
import operationsReducer from "./modules/operations";
import reportsReducer from "./modules/reports";
import searchReducer from "./modules/search";
import spinnerReducer from "./modules/spinner";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// @ts-ignore
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })) || compose;

const reducers = combineReducers({
  clientsState: clientReducer,
  visits: visitsReducer,
  notifications: notificationsReducer,
  auth: authReducer,
  productsState: productsReducer,
  servicesState: servicesReducer,
  operations: operationsReducer,
  reports: reportsReducer,
  searchState: searchReducer,
  spinnerReducer: spinnerReducer
});

const configureStore = () => createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));

type ReducersType = typeof reducers;
export type RootState = ReturnType<ReducersType>;

const store = configureStore();

export type StoreType = typeof store;

export default store;
