import axios from "axios";
import axiosService from "../../../axios-service";
import { ThunkDispatchType } from "..";
import { createErrorNotification, createSuccessNotification } from "../notifications";

export enum LoginActionType {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL"
}

export type LoginSuccess = {
  type: typeof LoginActionType.LOGIN_SUCCESS;
};

export type LoginFail = {
  type: typeof LoginActionType.LOGIN_FAIL;
};

export type LoginAction = LoginSuccess | LoginFail;

export type LoginState = {
  isLogged: boolean | null;
};

const initialState: LoginState = {
  isLogged: null
};

const reducer = (state = initialState, action: LoginAction): LoginState => {
  switch (action.type) {
    case LoginActionType.LOGIN_SUCCESS:
      return { isLogged: true };
    case LoginActionType.LOGIN_FAIL:
      return { isLogged: false };
    default:
      return state;
  }
};

export default reducer;

export const register = (login: string, password: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const registerRequest = await axiosService.post("/api/auth/registration", { username: login, password });
    const userId = registerRequest.data;
    if (userId) {
      dispatch(createSuccessNotification("Вдалось успішно створити користувача"));
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при створенні користувача: ${cause}`));
    }
  }
};

export const login = (login: string, password: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const registerRequest = await axiosService.post("/api/auth/login", { username: login, password });
    const userId = registerRequest.data.id;
    if (userId) {
      dispatch({ type: LoginActionType.LOGIN_SUCCESS });
      dispatch(createSuccessNotification("Вдалось успішно залогінитись"));
    }
  } catch (e) {
    dispatch({ type: LoginActionType.LOGIN_FAIL });
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при вході у акаунт: ${cause}`));
    }
  }
};

export const getCurrentUserInfo = () => async (dispatch: ThunkDispatchType) => {
  try {
    const whoAmIRequest = await axiosService.get("/api/auth/current-user");
    const userId = whoAmIRequest.data.id;
    if (userId) {
      dispatch({ type: LoginActionType.LOGIN_SUCCESS });
    } else {
      dispatch({ type: LoginActionType.LOGIN_FAIL });
    }
  } catch {
    dispatch({ type: LoginActionType.LOGIN_FAIL });
  }
};
