import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IProduct } from "../../redux/modules/products";
import { IOperation, getOperationsForProduct } from "../../redux/modules/operations";
import { formatPrice, formatDate } from "../../utils";

interface ProductOperationListProps {
  product: IProduct;
}

class ProductOperationList extends React.Component<ProductOperationListProps> {
  renderSupplyOperations() {
    const supplyOperations = this.props.product.operations?.filter((o) => o.type === "SUPPLY");

    if (!supplyOperations?.length) {
      return <p>Жодних операцій поповнення</p>;
    }

    const table = supplyOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-6">{formatDate(operation.date)} </div>
              <div className="col-3 text-right">
                <span>{operation.amount}</span>
              </div>
              <div className="col-3 text-right">{formatPrice(operation.amount * this.props.product.basePrice)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-6">Дата</div>
          <div className="col-3 text-right">Кількість, {this.getLocalizedUnitType()}</div>
          <div className="col-3 text-right">Собівартість, грн</div>
        </div>
      </li>
    );

    return table;
  }

  renderSellOperations() {
    const sellOperations = this.props.product.operations?.filter((o) => o.type === "SELL");

    if (!sellOperations?.length) {
      return <p>Жодних операцій продажу</p>;
    }

    const table = sellOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-3">{formatDate(operation.date)}</div>
              <div className="col-3 text-left">
                <Link to={`/client/${operation.clientId}?tab=products`}>{operation?.client?.name}</Link>
              </div>
              <div className="col-3 text-right">
                <span>{operation.amount}</span>
              </div>
              <div className="col-3 text-right">{formatPrice((operation.sellPrice - this.props.product.basePrice) * operation.amount)}</div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-3">Дата</div>
          <div className="col-3 text-left">Клієнт</div>
          <div className="col-3 text-right">Кількість, {this.getLocalizedUnitType()}</div>
          <div className="col-3 text-right">Вартість - Собівартість, грн</div>
        </div>
      </li>
    );
    return table;
  }

  renderUseOperations() {
    const useOperations = this.props.product.operations?.filter((o) => o.type === "USE");

    if (!useOperations?.length) {
      return <p>Жодних операцій Використання</p>;
    }

    const table = useOperations
      .sort((o1, o2) => (new Date(o1.date) < new Date(o2.date) ? -1 : 1))
      .map((operation: IOperation) => {
        return (
          <li className="list-group-item" key={operation.id}>
            <div className="row">
              <div className="col-6">{formatDate(operation.date)}</div>
              <div className="col-3 text-right">
                <span>{operation.amount}</span>
              </div>
            </div>
          </li>
        );
      });

    table.unshift(
      <li className="list-group-item" key="header">
        <div className="row">
          <div className="col-6">Дата</div>
          <div className="col-3 text-right">Кількість, {this.getLocalizedUnitType()}</div>
        </div>
      </li>
    );

    return table;
  }

  renderOperations() {
    if (!this.props.product.operations || this.props.product.operations.length === 0) {
      return <p>Ще немає жодної операції з товаром </p>;
    }

    return (
      <div>
        <br></br>
        <h3>Продаж</h3>
        <ul className="list-group">{this.renderSellOperations()}</ul>
        <br></br>
        <h3>Поповнення</h3>
        <ul className="list-group">{this.renderSupplyOperations()}</ul>
        <br></br>
        <h3>Використання</h3>
        <ul className="list-group">{this.renderUseOperations()}</ul>
        <br></br>
        <br></br>
      </div>
    );
  }

  render() {
    return (
      <>
        <h2>Oперації з товаром {this.props.product.name}</h2>
        {this.renderOperations()}
      </>
    );
  }

  getLocalizedUnitType() {
    return this.props.product.unitType === "piece" ? "шт" : "мл";
  }
}

export default ProductOperationList;
