import axios from "axios";
import axiosService from "../../../axios-service";
import { createSuccessNotification, createErrorNotification } from "../notifications";
import { VisitActionType } from "../visits";
import { IProduct, ProductActionType } from "../products";
import { ThunkDispatchType } from "..";
import { IClient } from "../clients";

export enum OperationActionType {
  GET_OPERATIONS = "GET_OPERATIONS"
}

export interface IOperation {
  id: number;
  type: string;
  amount: number;
  productId: number;
  product?: IProduct;
  sellPrice: number;
  clientId: number | null;
  client?: IClient;
  date: string;
}

export interface SellOperation {
  productId: number;
  amount: number;
  sellPrice: number;
  date: string;
  clientId: number;
}

export interface SupplyOperation {
  productId: number;
  amount: number;
  date: string;
}

export interface UseOperation {
  productId: number;
  amount: number;
  date: string;
  visitId?: number;
}

export type GetOperationsAction = {
  type: typeof OperationActionType.GET_OPERATIONS;
  payload: IOperation[];
};

export type OperationAction = GetOperationsAction;

const initialState: IOperation[] = [];

const reducer = (state = initialState, action: OperationAction): IOperation[] => {
  switch (action.type) {
    case OperationActionType.GET_OPERATIONS:
      return [...action.payload];
    default:
      return state;
  }
};

export default reducer;

export const getOperationsForProduct = (productId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/operations?productId=${productId}`);
    dispatch({ type: OperationActionType.GET_OPERATIONS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const getOperationsForClient = (clientId: string) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.get(`/api/v2/operations?clientId=${clientId}`);
    dispatch({ type: OperationActionType.GET_OPERATIONS, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addSellOperation = (operation: SellOperation) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/operations/sell", operation);
    dispatch(createSuccessNotification("Вдалось продати товар"));
    dispatch({ type: ProductActionType.GET_PRODUCT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addSupplyOperation = (operation: SupplyOperation) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/operations/supply", operation);
    dispatch(createSuccessNotification("Вдалось поповнити товар"));
    dispatch({ type: ProductActionType.GET_PRODUCT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addUseOperation = (operation: UseOperation) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/operations/use", operation);
    dispatch(createSuccessNotification("Вдалось списати товар"));
    dispatch({ type: ProductActionType.GET_PRODUCT, payload: response.data });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const addUseOperationOnVisit = (operation: UseOperation) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.post("/api/v2/operations/use-on-visit", operation);
    dispatch({ type: VisitActionType.UPDATE_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Вдалось списати товар"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};

export const deleteUseOperationOnVisit = (operationId: number) => async (dispatch: ThunkDispatchType) => {
  try {
    const response = await axiosService.delete(`/api/v2/operations/use-on-visit/${operationId}`);
    dispatch({ type: VisitActionType.UPDATE_VISIT, payload: response.data });
    dispatch(createSuccessNotification("Вдалось скасувати операцію"));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(createErrorNotification(`Виникла помилка при завантаженні даних: ${cause}`));
    }
  }
};
