import React from "react";
import { connect } from "react-redux";
import ServiceForm from "../../ServiceForm";
import { IService, addService } from "../../../redux/modules/services";
import history from "../../../history";

interface CreateServicePageProps {
  addService?: (service: IService) => void;
}

class CreateServicePage extends React.Component<CreateServicePageProps> {
  saveServiceHandler = (service: IService) => {
    this.props.addService && this.props.addService(service);
    history.push("/service-list");
  };

  render() {
    return (
      <div>
        <h1>Додати нову послугу</h1>
        <ServiceForm saveHandler={this.saveServiceHandler} />
      </div>
    );
  }
}

export default connect(null, { addService })(CreateServicePage);
