import React from "react";
import { connect } from "react-redux";
import { createErrorNotification } from "../../redux/modules/notifications";
import { IClient } from "../../redux/modules/clients";
import { formatDate } from "../../utils";

interface ClientFormProps {
  client?: IClient;
  saveHandler?: (client: IClient) => void;
  createErrorNotification?: (message: string) => void;
}

interface ClientFormState extends Partial<IClient> {}

class ClientForm extends React.Component<ClientFormProps, ClientFormState> {
  constructor(props: ClientFormProps) {
    super(props);
    const { id, name, birth, phone, email, notes } = this.props.client || { id: 0, name: "", birth: "", phone: "", email: "", notes: "" };
    this.state = {
      id,
      name,
      birth,
      phone,
      email,
      notes
    };
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof IClient
  ) => {
    const stateObj: ClientFormState = {};
    stateObj[key] = event.target.value as unknown as undefined;
    this.setState(stateObj);
  };

  save = () => {
    if (!this.state.phone || !this.state.name) {
      this.props.createErrorNotification && this.props.createErrorNotification("Телефон та Ім'я обов'язкові поля");
      return;
    }
    const newState = { ...this.state };
    this.props.saveHandler && this.props.saveHandler(newState as IClient);
  };

  render() {
    const { name, birth, phone, email, notes } = this.state;
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Ім'я</label>
          <input type="text" className="form-control" onChange={(event) => this.handleChange(event, "name")} id="formGroupExampleInput" value={name} required />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput2">Дата народження</label>
          <input
            type="date"
            className="form-control"
            onChange={(event) => this.handleChange(event, "birth")}
            id="formGroupExampleInput2"
            value={formatDate(birth)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput3">Телефон (Формат: 023-456-7890)</label>
          <input
            type="text"
            className="form-control"
            onChange={(event) => this.handleChange(event, "phone")}
            id="formGroupExampleInput3"
            value={phone}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput4">Електронна пошта</label>
          <input
            type="email"
            className="form-control"
            onChange={(event) => this.handleChange(event, "email")}
            id="formGroupExampleInput4"
            value={email || ""}
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput8">Нотатки</label>
          <br />
          <textarea style={{ minWidth: "100%" }} onChange={(event) => this.handleChange(event, "notes")} value={notes || ""} />
        </div>
        <button className="btn btn-outline-primary" onClick={this.save}>
          Зберегти
        </button>
      </form>
    );
  }
}

export default connect(null, { createErrorNotification })(ClientForm);
