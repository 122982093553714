import React from "react";
import { connect } from "react-redux";
import { IService } from "../../redux/modules/services";
import { createErrorNotification } from "../../redux/modules/notifications";

interface ServiceFormProps {
  service?: IService;
  saveHandler?: (service: IService) => void;
  createErrorNotification?: (message: string) => void;
}

interface ServiceFormState extends Partial<IService> {}

class ServiceForm extends React.Component<ServiceFormProps, ServiceFormState> {
  constructor(props: ServiceFormProps) {
    super(props);
    const { id, name, notes, price } = this.props.service || {
      _id: "",
      name: "",
      price: 0,
      notes: ""
    };
    this.state = {
      id,
      name,
      notes,
      price
    };
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof IService
  ) => {
    const stateObj: ServiceFormState = {};
    stateObj[key] = event.target.value as unknown as undefined;
    this.setState(stateObj);
  };

  save = () => {
    if (!this.state.name || !this.state.price) {
      this.props.createErrorNotification && this.props.createErrorNotification("Заповніть обов'язкові поля");
      return;
    }
    const newState = { ...this.state };
    this.props.saveHandler && this.props.saveHandler(newState as IService);
  };

  render() {
    const { name, notes, price } = this.state;
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Назва Послуги</label>
          <input type="text" className="form-control" onChange={(event) => this.handleChange(event, "name")} id="formGroupExampleInput" value={name} required />
        </div>

        <div className="form-group">
          <label htmlFor="formGroupExampleInput">Вартість</label>
          <input
            type="number"
            className="form-control"
            onChange={(event) => this.handleChange(event, "price")}
            id="formGroupExampleInput"
            value={price}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="formGroupExampleInput8">Нотатки</label>
          <br />
          <textarea style={{ minWidth: "100%" }} onChange={(event) => this.handleChange(event, "notes")} value={notes} />
        </div>
        <hr></hr>
        <button className="btn btn-primary" onClick={this.save}>
          Зберегти
        </button>
      </form>
    );
  }
}

export default connect(null, { createErrorNotification })(ServiceForm);
