import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ISearchClient, ISearchProduct, ISearchService, searchClients, clearSearch, searchProducts, searchServices } from "../../redux/modules/search";
import { RootState } from "../../redux";

interface SearchProps {
  searchClients: (query: string) => Promise<void>;
  searchProducts: (query: string) => Promise<void>;
  searchServices: (query: string) => Promise<void>;
  clearSearch: () => void;
  clients: ISearchClient[];
  products: ISearchProduct[];
  services: ISearchService[];
  searchType: SearchType;
}

export enum SearchType {
  CLIENTS,
  PRODUCTS,
  SERVICES
}

type SearchState = {
  query: string;
};

class TypeaheadSearch extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = { query: "" };
  }

  loadData = (query: string) => {
    switch (this.props.searchType) {
      case SearchType.CLIENTS:
        this.props.searchClients && this.props.searchClients(query);
        return;
      case SearchType.PRODUCTS:
        this.props.searchProducts && this.props.searchProducts(query);
        return;
      case SearchType.SERVICES:
        this.props.searchServices && this.props.searchServices(query);
        return;
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    if (query && query.length > 2) {
      this.loadData(query);
    } else {
      this.props.clearSearch && this.props.clearSearch();
    }
    this.setState({ query });
  };

  componentWillUnmount() {
    this.props.clearSearch && this.props.clearSearch();
  }

  renderResults() {
    const results = this.getData();
    if (!results.length) {
      return null;
    } else {
      return <ul className="list-group">{this.renderListItems()}</ul>;
    }
  }

  renderListItems() {
    return this.getData().map(({ id, name }) => {
      return (
        <Link to={this.getLink(id)} key={id}>
          <li className="list-group-item">{name}</li>
        </Link>
      );
    });
  }

  getData() {
    switch (this.props.searchType) {
      case SearchType.CLIENTS:
        return this.props.clients;
      case SearchType.PRODUCTS:
        return this.props.products;
      case SearchType.SERVICES:
        return this.props.services;
      default:
        return [];
    }
  }

  getLink = (id: number) => {
    switch (this.props.searchType) {
      case SearchType.CLIENTS:
        return `/client/${id}`;
      case SearchType.PRODUCTS:
        return `/product/${id}`;
      case SearchType.SERVICES:
        return `/service/${id}`;
    }
  };

  renderHeading() {
    switch (this.props.searchType) {
      case SearchType.CLIENTS:
        return <label htmlFor="formGroupExampleInput">Пошук Клієнтів</label>;
      case SearchType.PRODUCTS:
        return <label htmlFor="formGroupExampleInput">Пошук Товару</label>;
      case SearchType.SERVICES:
        return <label htmlFor="formGroupExampleInput">Пошук Послуг</label>;
    }
  }

  render() {
    return (
      <form>
        <div className="form-group">
          {this.renderHeading()}
          <input type="text" className="form-control" onChange={(event) => this.handleChange(event)} id="formGroupExampleInput" value={this.state.query} />
        </div>
        {this.renderResults()}
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { clients: state.searchState.clients, products: state.searchState.products, services: state.searchState.services };
};

export default connect(mapStateToProps, { searchClients, clearSearch, searchProducts, searchServices })(TypeaheadSearch);
